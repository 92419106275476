import { gql } from '@apollo/client';

import { WEBSITE_FIELDS, WEBSITE_RELATIONS } from '../fragments/website';

export const TRANSFER_WEBSITE = gql`
  ${WEBSITE_FIELDS}
  ${WEBSITE_RELATIONS}
  mutation TransferWebsite(
    $websiteId: String!
    $transferFromSubscriptionId: String!
    $transferToSubscriptionId: String!
  ) {
    transferWebsite(
      websiteId: $websiteId
      transferFromSubscriptionId: $transferFromSubscriptionId
      transferToSubscriptionId: $transferToSubscriptionId
    ) {
      ...WebsiteFields
      ...WebsiteRelations
    }
  }
`;
