import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

import { IS_AUTHENTICATED } from 'graphql/queries/isAuthenticated';

const Logout: React.FunctionComponent = (props) => {
  const client = useApolloClient();

  useEffect(() => {
    client.clearStore();
    client.writeQuery({ query: IS_AUTHENTICATED, data: { isAuthenticated: false } });
    localStorage.removeItem('token');
    if (localStorage.getItem('supportAccessUserId')) localStorage.setItem('supportAccessUserId', '');
    window.location.replace('/login' + window.location.search);
  });

  return null;
};

export default Logout;
