import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useApolloClient } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { Button, Input, Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { IS_AUTHENTICATED } from 'graphql/queries/isAuthenticated';
import { REGISTER } from 'graphql/mutations/register';

const SignupWithEmail: React.FunctionComponent = (props) => {
  const { t } = useTranslation();

  const client = useApolloClient();

  const [register] = useMutation(REGISTER);

  const onSubmitHandler = async (values: any) => {
    toast.dismiss();

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.email,
      email: values.email,
      password: values.password,
    };

    await register({ variables: { user } }).then(
      (success) => {
        client.writeQuery({ query: IS_AUTHENTICATED, data: { isAuthenticated: true } });
        localStorage.setItem('token', success.data.register.jwt);
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  return (
    <div className="register-page register-with-email">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal" data-test="skLogoImage">
              <img src={require('assets/img/sk-logo-black.png')} alt="SquareKicker" />
            </a>
          </div>
          <div className="auth-form">
            <h2 data-test="signupLandingPageTitle">{t('signup.createYourAccount')}</h2>

            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                acceptTerms: false,
                registerForm: '',
              }}
              validate={(values) => {
                const errors: any = {};

                if (!values.firstName) {
                  errors.firstName = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }

                if (!values.lastName) {
                  errors.lastName = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }
                if (!values.email) {
                  errors.email = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = errors.email = t('formValidation.invalidEmail');
                }

                if (!values.password) {
                  errors.password = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }

                if (!values.confirmPassword) {
                  errors.confirmPassword = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }

                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = t('formValidation.passwordsDontMatch');
                }

                if (!values.acceptTerms) {
                  errors.acceptTerms = t('formValidation.acceptTermsAndConditions');
                }

                return errors;
              }}
              onSubmit={onSubmitHandler}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup className={errors.firstName !== undefined ? 'has-danger' : ''}>
                        <Label for="firstName" data-test="firstNameLabel">
                          {t('signup.firstName')}
                        </Label>
                        <Field name="firstName" as={Input} />
                        <ErrorMessage
                          name="firstName"
                          component={() => <p className="text-danger">{errors.firstName}</p>}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={errors.lastName !== undefined ? 'has-danger' : ''}>
                        <Label for="lastName" data-test="lastNameLabel">
                          {t('signup.lastName')}
                        </Label>
                        <Field name="lastName" as={Input} />
                        <ErrorMessage
                          name="lastName"
                          component={() => <p className="text-danger">{errors.lastName}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className={errors.email !== undefined ? 'has-danger' : ''}>
                    <Label for="email" data-test="emailLabel">
                      {t('signup.email')}
                    </Label>
                    <Field type="email" name="email" autoComplete="email" as={Input} noValidate />
                    <ErrorMessage name="email" component={() => <p className="text-danger">{errors.email}</p>} />
                  </FormGroup>
                  <FormGroup className={errors.password !== undefined ? 'has-danger' : ''}>
                    <Label for="password" data-test="passwordLabel">
                      {t('signup.password')}
                    </Label>
                    <Field type="password" name="password" autoComplete="new-password" as={Input} />
                    <ErrorMessage name="password" component={() => <p className="text-danger">{errors.password}</p>} />
                  </FormGroup>
                  <FormGroup className={errors.confirmPassword !== undefined ? 'has-danger' : ''}>
                    <Label for="confirmPassword" data-test="confirmPasswordLabel">
                      {t('signup.confirmPassword')}
                    </Label>
                    <Field type="password" name="confirmPassword" autoComplete="new-password" as={Input} />
                    <ErrorMessage
                      name="confirmPassword"
                      component={() => (
                        <p className="text-danger" data-test="confirmPasswordErrorMessage">
                          {errors.confirmPassword}
                        </p>
                      )}
                    />
                  </FormGroup>

                  <FormGroup check>
                    <Label check data-test="acceptTermsLabel">
                      <Field type="checkbox" name="acceptTerms" className="form-check-input" />
                      <span className="form-check-sign"></span>
                      <Trans i18nKey="signup.termsAndConditions">
                        I agree to{' '}
                        <a
                          href="https://squarekicker.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-test="termsAndConditionsLink"
                        >
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a
                          href="https://squarekicker.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-test="privacyPolicyLink"
                        >
                          Privacy Policy
                        </a>
                      </Trans>
                    </Label>
                    <ErrorMessage
                      name="acceptTerms"
                      component={() => (
                        <p className="text-danger" data-test="acceptTermsErrorMessage">
                          {errors.acceptTerms}
                        </p>
                      )}
                    />
                  </FormGroup>

                  <ErrorMessage
                    name="registerForm"
                    component={() => (
                      <Alert color="danger" data-test="registerFormErrorMessage">
                        {errors.registerForm}
                      </Alert>
                    )}
                  />
                  <Row>
                    <Col md="auto">
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn-round"
                        color="primary"
                        data-test="signUpButton"
                      >
                        {t('signup.signUp')}
                      </Button>
                    </Col>
                    <Col md="auto" className="login-link" data-test="logInLink">
                      <span>
                        <Trans i18nKey="signup.logIn">
                          Already a member? <Link to="/login">Log In</Link>
                        </Trans>
                      </span>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignupWithEmail;
