import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormText,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { SUBSCRIPTIONS } from 'graphql/queries/subscriptions';
import { PLAN_TYPE } from 'consts';
import { checkActiveWebsites, checkWebsiteActivations } from 'functions';

import SubscriptionSummary from './SubscriptionSummary';
import ActivationsReachedTooltip from 'components/ActivationsReachedTooltip';

const TransferWebsiteModal: React.FunctionComponent<{
  toggle: any;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchFilter, setSearchFilter] = useState('');

  const searchFilterFunction = (subscription: any) => {
    if (searchFilter === '') return true;
    try {
      return (
        subscription.id === searchFilter ||
        (subscription.plan.name && subscription.plan.name.search(new RegExp(searchFilter, 'i')) !== -1) ||
        (subscription.expiryDate &&
          moment
            .utc(subscription.expiryDate)
            .add(1, 'day')
            .local()
            .format('MMM D, YYYY')
            .search(new RegExp(searchFilter, 'i')) !== -1) ||
        (subscription.stripeSubscription?.plan.metadata.billingCycle &&
          subscription.stripeSubscription?.plan.metadata.billingCycle.search(new RegExp(searchFilter, 'i')) !== -1) ||
        subscription.websites.some((website: any) => website.domain.search(new RegExp(searchFilter, 'i')) !== -1)
      );
    } catch (e) {
      return false;
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState('');

  const { subscriptionId, websiteId } = useParams<{ subscriptionId: string; websiteId: string }>();

  const { data, loading } = useQuery(SUBSCRIPTIONS);

  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );

  if (loading || !data) return null;

  return (
    <Modal className="website-settings-modal" size="lg" isOpen={true} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {t('dashboard.transferWebsiteModalTitle')}
      </ModalHeader>
      <ModalBody>
        <Row className="website-list-search">
          <Col sm="12">
            <h5>Search</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <FormGroup style={{ paddingTop: '10px' }}>
                <Input
                  type="search"
                  name="search"
                  id="website-list-search-filter"
                  placeholder="plan or website"
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                />
                <FormText color="muted" style={{ float: 'right' }}>
                  Showing{' '}
                  {
                    data.subscriptions
                      .filter(
                        (subscription: any) =>
                          (subscription.plan.type === PLAN_TYPE.DESIGNER ||
                            subscription.plan.type === PLAN_TYPE.AGENCY) &&
                          subscription.id !== subscriptionId,
                      )
                      .filter(searchFilterFunction).length
                  }{' '}
                  of{' '}
                  {
                    data.subscriptions.filter(
                      (subscription: any) =>
                        (subscription.plan.type === PLAN_TYPE.DESIGNER ||
                          subscription.plan.type === PLAN_TYPE.AGENCY) &&
                        subscription.id !== subscriptionId,
                    ).length
                  }
                </FormText>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <div className="website-list">
          {data.subscriptions
            .filter(
              (subscription: any) =>
                (subscription.plan.type === PLAN_TYPE.DESIGNER || subscription.plan.type === PLAN_TYPE.AGENCY) &&
                subscription.id !== subscriptionId,
            )
            .filter(searchFilterFunction)
            .map((subscription: any) => {
              return (
                <Row key={subscription.id} className="website-list-row">
                  <Col lg="6" xl="8">
                    <h3>{subscription.plan.name}</h3>
                    <SubscriptionSummary subscription={subscription} />
                  </Col>

                  <Col className="website-list-actions-col">
                    <Button
                      id={'TransferWebsiteButton' + subscription.id}
                      className="btn-round no-hide"
                      color="success"
                      onClick={() => {
                        checkActiveWebsites(subscription) && checkWebsiteActivations(subscription)
                          ? history.push(
                              '/subscription/' +
                                subscriptionId +
                                '/website/' +
                                websiteId +
                                '/transfer/' +
                                subscription.id +
                                '/confirm',
                            )
                          : setTooltipOpen('TransferWebsiteButton' + subscription.id);
                      }}
                    >
                      {t('dashboard.transferWebsiteModalTransferButton')}
                    </Button>
                    <ActivationsReachedTooltip
                      tooltipOpen={tooltipOpen}
                      setTooltipOpen={setTooltipOpen}
                      subscription={subscription}
                      target={'TransferWebsiteButton' + subscription.id}
                    />
                  </Col>
                </Row>
              );
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.closeButton')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TransferWebsiteModal;
