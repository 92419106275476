import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from 'reactstrap';

import SQSPConnectAccount from './components/SQSPConnectAccount';
import ProfileForm from './components/ProfileForm';
import EmailSettingsForm from './components/EmailSettingsForm';
import ChangePasswordForm from './components/ChangePasswordForm';

const Account: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="content account-view">
      <Row>
        <Col md="12" className="sk-page-header">
          <div className="sk-page-title">
            <h1>{t('account.pageTitle')}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <SQSPConnectAccount />
              <ProfileForm />
              <EmailSettingsForm />
              <ChangePasswordForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Account;
