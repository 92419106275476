import React, { useEffect } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';

import { graphQLErrorHandler } from 'graphql/apollo';
import { SQSP_OAUTH_REGISTER } from 'graphql/mutations/sqspOAuthRegister';
import OAuthConnecting from 'components/OAuthConnecting';
import { loginSuccessFunction } from 'functions';
import i18next from 'i18next';

const OAuthRegister = () => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();

  const [sqspOAuthRegister] = useMutation(SQSP_OAUTH_REGISTER);

  useEffect(() => {
    const sqspOAuthState = localStorage.getItem('SQSPOAuthState');
    // @ts-ignore
    const sqspUserData = JSON.parse(decodeURIComponent(atob(localStorage.getItem('SQSPUserData') || '') || null));

    localStorage.removeItem('SQSPOAuthConnect');
    localStorage.removeItem('SQSPOAuthState');
    localStorage.removeItem('SQSPUserData');

    // Check State
    if (!sqspOAuthState || !sqspUserData) {
      graphQLErrorHandler({});
      history.push('/');
    } else {
      const { state, code, error } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder: decodeURIComponent,
      });

      if (error) {
        graphQLErrorHandler({
          title: i18next.t('oauth.accessDeniedErrorTitle'),
          message: i18next.t('oauth.connectAccountAccessDeniedErrorMessage'),
        });
        history.push(`/signup-with-squarespace`);
      } else if (state === sqspOAuthState) {
        // Check CSRF Token matches
        // SQSP OAuth Register
        sqspOAuthRegister({
          variables: {
            sqspAuthCode: code,
            firstName: sqspUserData.firstName,
            lastName: sqspUserData.lastName,
            username: sqspUserData.email,
            email: sqspUserData.email,
          },
        }).then(
          (success) => {
            loginSuccessFunction(success.data.SQSPOAuthRegister);
            history.push(`/dashboard`);
          },
          (error) => {
            graphQLErrorHandler(error);
            history.push(`/signup-with-squarespace`);
          },
        );
      } else {
        graphQLErrorHandler({});
        history.push(`/signup-with-squarespace`);
      }
    }
  }, [history, location.search, sqspOAuthRegister, client]);

  return <OAuthConnecting />;
};

export default OAuthRegister;
