import { gql } from '@apollo/client';

export const CREATE_SQSP_OAUTH_REQUEST = gql`
  mutation CreateSQSPOAuthRequest($input: createSqspOauthRequestInput!) {
    createSqspOauthRequest(input: $input) {
      sqspOauthRequest {
        id
      }
    }
  }
`;
