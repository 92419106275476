import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const RESET_PASSWORD = gql`
  ${ME_FIELDS}
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        ...MeFields
        role {
          id
          name
          description
          type
        }
      }
    }
  }
`;
