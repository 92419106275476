import { gql } from '@apollo/client';

export const WEBSITE_FIELDS = gql`
  fragment WebsiteFields on Website {
    id
    domain
    title
    lastSKRequestDate
    active
    lastActivationDate
    sqspSiteId
    sqspConnected
  }
`;

export const WEBSITE_RELATIONS = gql`
  fragment WebsiteRelations on Website {
    sk_token {
      id
      token
    }
    ss_users {
      id
      firstName
      lastName
      email
    }
  }
`;
