import { gql } from '@apollo/client';

import { USER_FIELDS } from '../fragments/user';

export const UPDATE_USER = gql`
  ${USER_FIELDS}
  mutation UpdateUser($user: updateUserInput!) {
    updateUser(input: $user) {
      user {
        ...UserFields
      }
    }
  }
`;
