import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { BILLING_CYCLE } from 'consts';
import { GET_STARTED_STEP_1_PATH } from 'routes';
import { UPCOMING_STRIPE_INVOICE } from 'graphql/queries/upcomingStripeInvoice';

const NewSubscriptionDetails: React.FunctionComponent<{
  subscription: any;
  couponCode?: string;
  discount?: number | null;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  const currentSubscription = history?.location?.state?.currentSubscription;
  const reactivate = history?.location?.state?.reactivate;

  const { data } = useQuery(UPCOMING_STRIPE_INVOICE, {
    fetchPolicy: 'network-only',
    variables: {
      subscriptionId: reactivate ? null : currentSubscription?.id,
      planId: props.subscription.plan.id,
      billingCycle: props.subscription.billingCycle,
      coupon: props.couponCode,
    },
  });

  return (
    <>
      <h3>{t('dashboard.subscriptionSectionHeader')}</h3>

      {/* Plan */}
      <p data-test="new-subscription-details-plan">
        <b>{t('dashboard.subscriptionDetailsPlan')}:</b> {props.subscription.plan.name}
        <Link
          data-test="payment-change-plan-link"
          className="float-right text-small"
          to={{
            pathname: GET_STARTED_STEP_1_PATH,
            state: { currentSubscription, reactivate },
          }}
        >
          {t('newSubscription.step2ChangePlan')}
        </Link>
      </p>

      {/* Billing Cycle */}
      <p data-test="new-subscription-details-billing-cycle">
        <b>{t('dashboard.subscriptionDetailsBillingCycle')}:</b>{' '}
        {props.subscription.billingCycle === BILLING_CYCLE.ANNUAL
          ? t('dashboard.subscriptionDetailsBillingCycleAnnual')
          : t('dashboard.subscriptionDetailsBillingCycleMonthly')}
      </p>

      {/* Plan Price */}
      <p data-test="new-subscription-details-price">
        <b>{t('dashboard.subscriptionDetailsPrice')}:</b>{' '}
        <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
        <span className="pricing-price">
          {props.subscription.billingCycle === BILLING_CYCLE.ANNUAL
            ? (props.subscription.plan.annualPrice / 100 / 12).toFixed(2)
            : (props.subscription.plan.monthlyPrice / 100).toFixed(2)}
        </span>
        <span className="pricing-month">{t('newSubscription.step1PerMonth')}</span>
      </p>

      {/* Invoice */}
      {data && (
        <>
          <hr></hr>
          {/* Line Items */}
          {data.upcomingStripeInvoice.stripeInvoice.lines.data.map((line: any) => (
            <div key={line.id}>
              <div className="d-flex justify-content-between align-items-end">
                <p style={{ marginRight: '1rem' }}>
                  <span>{line.description}:</span>
                </p>
                <p data-test="payment-line-item" className={line.amount < 0 ? 'text-success' : ''}>
                  <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                  <span className="pricing-price">{(line.amount / 100).toFixed(2)}</span>
                </p>
              </div>
              {/* Discounts*/}
              {line.discount_amounts.find(
                (discount_amounts: any) =>
                  discount_amounts.discount === data.upcomingStripeInvoice.stripeInvoice.discount.id,
              )?.amount > 0 && (
                <div className="d-flex justify-content-between align-items-end">
                  <p style={{ marginRight: '1rem' }}>
                    {data.upcomingStripeInvoice.stripeInvoice.discount.coupon.name}{' '}
                    {data.upcomingStripeInvoice.stripeInvoice.discount.coupon.percent_off}% off for{' '}
                    {data.upcomingStripeInvoice.stripeInvoice.discount.coupon.duration_in_months} months
                  </p>
                  <p className="text-success">
                    <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                    <span className="pricing-price">
                      -
                      {(
                        line.discount_amounts.find(
                          (discount_amounts: any) =>
                            discount_amounts.discount === data.upcomingStripeInvoice.stripeInvoice.discount.id,
                        )?.amount / 100
                      ).toFixed(2)}
                    </span>
                  </p>
                </div>
              )}
            </div>
          ))}

          {/* Account Credit */}
          {data.upcomingStripeInvoice.stripeInvoice.starting_balance < 0 && (
            <>
              <div className="d-flex justify-content-between align-items-end">
                <p style={{ marginRight: '1rem' }}>
                  <span>{t('dashboard.subscriptionDetailsAccountCredit')}:</span>
                </p>
                <p className={data.upcomingStripeInvoice.stripeInvoice.starting_balance < 0 ? 'text-success' : ''}>
                  <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                  <span className="pricing-price">
                    {(data.upcomingStripeInvoice.stripeInvoice.starting_balance / 100).toFixed(2)}
                  </span>
                </p>
              </div>
            </>
          )}

          {/* Total */}
          <br />
          <div data-test="payment-plan-total" className="d-flex justify-content-between align-items-end">
            <p style={{ marginRight: '1rem' }}>
              <b>{t('dashboard.subscriptionDetailsTotal')}:</b>
            </p>
            <p>
              <b>
                <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                <span className="pricing-price">
                  {data.upcomingStripeInvoice.stripeInvoice.lines.data[0].plan.metadata === BILLING_CYCLE.ANNUAL
                    ? (data.upcomingStripeInvoice.stripeInvoice.amount_due / 100).toFixed(2)
                    : (data.upcomingStripeInvoice.stripeInvoice.amount_due / 100).toFixed(2)}
                </span>
              </b>
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default NewSubscriptionDetails;
