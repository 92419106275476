import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Input, Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FORGOT_PASSWORD } from 'graphql/mutations/forgotPassword';

const ForgotPassword: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const initialForgotText = t('forgotPassword.forgotText');

  const [forgotText, setForgotText] = useState(initialForgotText);

  const [hideForm, setHideForm] = useState(false);

  const onSubmitHandler = async (values: any, { resetForm }: any) => {
    toast.dismiss();

    await forgotPassword({ variables: { email: values.email } }).then(
      (success) => {
        if (success.data?.forgotPassword?.ok === true) {
          setHideForm(true);
          setForgotText(t('forgotPassword.resetSentText'));
        }
      },
      (error) => {
        graphQLErrorHandler(error);
        resetForm({ values });
      },
    );
  };

  return (
    <div className="login-page">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal">
              <img src={require('assets/img/sk-logo-black.png')} data-test="sk-logo-image" alt="SquareKicker" />
            </a>
          </div>
          <div className="auth-form">
            <h2 data-test="forgot-password-title">{t('forgotPassword.forgotPassword')}</h2>
            {hideForm && (
              <Alert color="success" data-test="forgot-password-success-msg">
                <p>{forgotText}</p>
              </Alert>
            )}
            {!hideForm && (
              <>
                <p data-test="forgot-password-description">{forgotText}</p>
                <Formik
                  validateOnBlur={false}
                  validateOnChange={false}
                  initialValues={{
                    email: '',
                    forgotForm: '',
                  }}
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.email) {
                      errors.email = '';
                      errors.forgotForm = t('formValidation.forgotSomething');
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = t('formValidation.invalidEmail');
                    }

                    return errors;
                  }}
                  onSubmit={onSubmitHandler}
                >
                  {({ dirty, submitCount, errors }) => (
                    <Form>
                      <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
                        <FormGroup className={errors.email !== undefined ? 'has-danger' : ''}>
                          <Label for="email" data-test="forgot-password-email-label">
                            {t('forgotPassword.email')}
                          </Label>
                          <Field type="email" data-test="forgot-password-email-input" name="email" as={Input} />
                          <ErrorMessage name="email" component={() => <p className="text-danger">{errors.email}</p>} />
                        </FormGroup>

                        <ErrorMessage
                          name="forgotForm"
                          component={() => <Alert color="danger">{errors.forgotForm}</Alert>}
                        />
                        <Row>
                          <Col md="auto">
                            <Button
                              disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                              type="submit"
                              className="btn-round"
                              data-test="forgot-password-send-button"
                              color="primary"
                            >
                              {t('forgotPassword.sendResetLink')}
                            </Button>
                          </Col>
                          <Col md="auto" className="signup-link">
                            <span data-test="forgot-password-sign-up">
                              <Trans i18nKey="forgotPassword.signUp">
                                Not a member? <Link to="/signup">Sign Up</Link>
                              </Trans>
                            </span>
                          </Col>
                        </Row>
                      </fieldset>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPassword;
