import { gql } from '@apollo/client';

import { PLAN_FIELDS } from '../fragments/plan';

export const PLANS = gql`
  ${PLAN_FIELDS}
  query Plans {
    plans {
      ...PlanFields
    }
  }
`;
