import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import { BILLING_CYCLE } from 'consts';
import { GET_STARTED_STEP_1_PATH, GET_STARTED_STEP_3_PATH } from 'routes';

import PaymentForm from '../PaymentForm/PaymentForm';

const Step2: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();

  const nextStep = (state: any) => {
    history.replace(GET_STARTED_STEP_3_PATH, state);
  };

  const previousStep = () => {
    history.push(GET_STARTED_STEP_1_PATH, {
      currentSubscription: history?.location?.state?.currentSubscription,
      reactivate: history?.location?.state?.reactivate,
    });
  };

  if (history?.location?.state === undefined) {
    // No Plan Selected go back to Step 1
    history.push(GET_STARTED_STEP_1_PATH, {
      currentSubscription: history?.location?.state?.currentSubscription,
      reactivate: history?.location?.state?.reactivate,
    });
    return null;
  }

  const { selectedPlan, billingCycle } = history?.location?.state;

  if (!selectedPlan || billingCycle === undefined) {
    // No Plan Selected go back to Step 1
    history.push(GET_STARTED_STEP_1_PATH, {
      currentSubscription: history?.location?.state?.currentSubscription,
      reactivate: history?.location?.state?.reactivate,
    });
    return null;
  }

  const subscription = {
    plan: selectedPlan,
    billingCycle,
    expiryDate:
      billingCycle === BILLING_CYCLE.ANNUAL
        ? moment().add(1, 'year').format('YYYY-MM-DD')
        : moment().add(1, 'month').format('YYYY-MM-DD'),
  };

  return (
    <>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <h2>{t('newSubscription.step2Title')}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <PaymentForm previousStep={previousStep} nextStep={nextStep} subscription={subscription} />
        </Col>
      </Row>
    </>
  );
};

export default Step2;
