import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import Select from 'components/Select';
import { countryCodes } from 'consts';

import { ME } from 'graphql/queries/me';
import { UPDATE_USER } from 'graphql/mutations/updateUser';

const ProfileForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery(ME);
  const [updateUser] = useMutation(UPDATE_USER);

  if (loading || !data) return null;

  const initialValues = {
    firstName: data.me.firstName || '',
    lastName: data.me.lastName || '',
    companyName: data.me.companyName || '',
    website: data.me.website || '',
    country: data.me.country || undefined,
    city: data.me.city || '',
    preferredLanguages: data.me.preferredLanguages || '',
    userType: data.me.userType || undefined,
  };

  const onSubmitHandler = async (values: any, { resetForm, setSubmitting }: any) => {
    toast.dismiss();

    let user = {
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      website: values.website,
      country: values.country,
      city: values.city,
      preferredLanguages: values.preferredLanguages,
      userType: values.userType,
    };

    await updateUser({
      variables: {
        user: {
          where: {
            id: data.me.id,
          },
          data: user,
        },
      },
    }).then(
      (success) => {
        refetch().then((refetchSuccess) => {
          const newValues = {
            firstName: refetchSuccess.data.me.firstName || '',
            lastName: refetchSuccess.data.me.lastName || '',
            companyName: refetchSuccess.data.me.companyName || '',
            website: refetchSuccess.data.me.website || '',
            country: refetchSuccess.data.me.country || undefined,
            city: refetchSuccess.data.me.city || '',
            preferredLanguages: refetchSuccess.data.me.preferredLanguages || '',
            userType: refetchSuccess.data.me.userType || undefined,
          };

          resetForm({ values: newValues });
        });
      },
      (error) => {
        graphQLErrorHandler(error);
        resetForm({ values });
      },
    );
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};

        if (!values.firstName) {
          errors.firstName = t('formValidation.forgotSomething');
        }

        if (!values.lastName) {
          errors.lastName = t('formValidation.forgotSomething');
        }

        return errors;
      }}
      onSubmit={onSubmitHandler}
    >
      {({ dirty, submitCount, errors, resetForm }) => (
        <Form>
          <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
            <Row>
              <Col sm="12">
                <h5 data-test="account-profile-title">Profile</h5>
                <Row style={{ padding: 0, borderTop: 0 }}>
                  <Col md="6" className="form-split-input">
                    <FormGroup className={errors.firstName !== undefined ? 'has-danger' : ''}>
                      <Label for="firstName">{t('account.firstName')}</Label>
                      <Field data-test="account-profile-first-name" name="firstName" as={Input} />
                      <ErrorMessage
                        name="firstName"
                        component={() => (
                          <p className="text-danger" data-test="account-profile-first-name-error">
                            {errors.firstName}
                          </p>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" style={{ padding: 0 }}>
                    <FormGroup className={errors.lastName !== undefined ? 'has-danger' : ''}>
                      <Label for="lastName">{t('account.lastName')}</Label>
                      <Field data-test="account-profile-last-name" name="lastName" as={Input} />
                      <ErrorMessage
                        name="lastName"
                        component={() => (
                          <p className="text-danger" data-test="account-profile-last-name-error">
                            {errors.lastName}
                          </p>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className={errors.companyName !== undefined ? 'has-danger' : ''}>
                  <Label for="companyName">{t('account.companyName')}</Label>
                  <Field data-test="account-profile-company-name" type="text" name="companyName" as={Input} />
                  <ErrorMessage
                    name="companyName"
                    component={() => <p className="text-danger">{errors.companyName}</p>}
                  />
                </FormGroup>
                <FormGroup className={errors.website !== undefined ? 'has-danger' : ''}>
                  <Label for="website">{t('account.website')}</Label>
                  <Field data-test="account-profile-website" name="website" as={Input} />
                  <ErrorMessage name="website" component={() => <p className="text-danger">{errors.website}</p>} />
                </FormGroup>
                <FormGroup className={errors.city !== undefined ? 'has-danger' : ''}>
                  <Label for="city">{t('account.city')}</Label>
                  <Field data-test="account-profile-city" name="city" as={Input} />
                  <ErrorMessage name="city" component={() => <p className="text-danger">{errors.city}</p>} />
                </FormGroup>
                <FormGroup className={errors.country !== undefined ? 'has-danger' : ''}>
                  <Label for="country">{t('account.country')}</Label>
                  <Select className="form-control" data-test="account-profile-country" name="country">
                    <option value={undefined} hidden></option>
                    {Object.keys(countryCodes).map((countryCode: any) => (
                      <option key={countryCode} value={countryCode}>
                        {countryCodes[countryCode]}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage name="country" component={() => <p className="text-danger">{errors.country}</p>} />
                </FormGroup>
                <FormGroup className={errors.preferredLanguages !== undefined ? 'has-danger' : ''}>
                  <Label for="preferredLanguages">{t('account.preferredLanguages')}</Label>
                  <Field name="preferredLanguages" data-test="account-profile-preferred-language" as={Input} />
                  <ErrorMessage name="preferredLanguages" className="form-select" />
                </FormGroup>
                <FormGroup className={errors.userType !== undefined ? 'has-danger' : ''}>
                  <Label for="userType">{t('account.userType')}</Label>
                  <Select className="form-control" name="userType" data-test="account-profile-user-type">
                    <option value={undefined} hidden></option>
                    <option value="DIY">{t('account.userTypeDIY')}</option>
                    <option value="DESIGNER">{t('account.userTypeDesigner')}</option>
                  </Select>
                  <ErrorMessage name="country" component={() => <p className="text-danger">{errors.country}</p>} />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    type="submit"
                    data-test="account-profile-update-button"
                    className="btn-round"
                    color="primary"
                  >
                    {t('account.updateProfileButton')}
                  </Button>
                  <Button
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    onClick={() => resetForm({ values: initialValues })}
                    data-test="account-profile-cancel-button"
                    className="btn-round"
                    color="danger"
                  >
                    {t('common.cancelButton')}
                  </Button>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
