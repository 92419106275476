import React from 'react';

const Help: React.FunctionComponent = () => {
  return (
    <div className="iframe-container">
      <iframe src="https://squarekicker.com/support#get-in-touch" title="SquareKicker Support" frameBorder="0"></iframe>
    </div>
  );
};

export default Help;
