import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AuthSidebar: React.FunctionComponent = (props) => {
  const location: any = useLocation();

  const image =
    qs.parse(location.search, { ignoreQueryPrefix: true })?.parrotmode === ''
      ? require('assets/img/bg/sk-parrot.jpg')
      : require('assets/img/bg/sk-trailblaze.png');

  return (
    <div
      className="sidebar auth-sidebar"
      style={{
        backgroundImage: `url(${image})`,
      }}
    ></div>
  );
};

export default AuthSidebar;
