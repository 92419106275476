import { gql } from '@apollo/client';

export const ME_FIELDS = gql`
  fragment MeFields on UsersPermissionsMe {
    id
    username
    email
    firstName
    lastName
    companyName
    website
    country
    city
    preferredLanguages
    userType
    confirmed
    blocked
    stripePaymentMethods
    sqspConnected
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on UsersPermissionsUser {
    id
    username
    email
    firstName
    lastName
    companyName
    website
    country
    city
    preferredLanguages
    userType
    confirmed
    blocked
    stripePaymentMethods
    sqspConnected
  }
`;
