import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';

import { BILLING_CYCLE, PLAN_TYPE } from 'consts';
import { GET_STARTED_STEP_2_PATH } from 'routes';

import { PLANS } from 'graphql/queries/plans';

import PricingCard from '../PricingCard';

const Step1: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();

  // @ts-ignore
  const selectedPlanId = new URLSearchParams(useLocation().search).get('selectedPlanId');

  const selectedPlanFilter = (plan: any) => {
    if (selectedPlanId) return plan.id === selectedPlanId;
    return true;
  };

  const { data, loading } = useQuery(PLANS);

  const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE.ANNUAL);

  const nextStep = (selectedPlan: any, currentSubscription: any) => {
    if (currentSubscription) {
      // Upgrade Subscription
      history.push(GET_STARTED_STEP_2_PATH, {
        selectedPlan,
        billingCycle,
        currentSubscription,
        reactivate: history?.location?.state?.reactivate,
      });
    } else {
      // New Subscription
      history.push(GET_STARTED_STEP_2_PATH, { selectedPlan, billingCycle });
    }
  };

  if (loading || !data) return null;

  const currentSubscription = history?.location?.state?.currentSubscription;
  let currentPlanIndex = -1;

  if (currentSubscription) {
    currentPlanIndex = data.plans
      .filter((plan: any) => plan.type !== PLAN_TYPE.FREE_TRIAL)
      .map((plan: any) => plan.id)
      .indexOf(currentSubscription.plan.id);
  }

  return (
    <>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <h1 data-test="step-1-title">{t('newSubscription.step1Title')}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <h5>{t('newSubscription.step1Subtitle')}</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <p className="save-annually">{t('newSubscription.step1SaveAnnually')}</p>
          <div className="d-flex justify-content-center align-items-center">
            <ButtonGroup>
              <Button
                className="btn-round "
                data-test="step-1-annual-button"
                color="charcoal"
                onClick={() => setBillingCycle(BILLING_CYCLE.ANNUAL)}
                active={billingCycle === BILLING_CYCLE.ANNUAL}
              >
                {t('newSubscription.step1Annual')}
              </Button>
              <Button
                className="btn-round"
                data-test="step-1-monthly-button"
                color="charcoal"
                onClick={() => setBillingCycle(BILLING_CYCLE.MONTHLY)}
                active={billingCycle === BILLING_CYCLE.MONTHLY}
              >
                {t('newSubscription.step1Monthly')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row className="justify-content-center">
        {data.plans
          .filter((plan: any) => plan.type !== PLAN_TYPE.FREE_TRIAL)
          .filter(selectedPlanFilter)
          .map((plan: any, index: number) => (
            <PricingCard
              key={plan.id}
              plan={plan}
              billingCycle={billingCycle}
              nextStep={() => nextStep(plan, currentSubscription)}
              currentPlan={
                index === currentPlanIndex &&
                (currentSubscription?.plan?.billingCycle === billingCycle ||
                  currentSubscription?.stripeSubscription?.plan?.metadata?.billingCycle === billingCycle)
              }
              downgrade={
                (history?.location?.state?.reactivate &&
                  (currentSubscription?.plan?.type === PLAN_TYPE.DESIGNER ||
                    currentSubscription?.plan?.type === PLAN_TYPE.AGENCY) &&
                  (plan?.type === PLAN_TYPE.FREE_TRIAL ||
                    plan?.type === PLAN_TYPE.BASIC ||
                    plan?.type === PLAN_TYPE.PRO)) ||
                (!history?.location?.state?.reactivate &&
                  (index < currentPlanIndex ||
                    (index === currentPlanIndex && (currentPlanIndex === 0 || billingCycle === BILLING_CYCLE.MONTHLY))))
              }
            />
          ))}
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <h5>{t('newSubscription.step1CancelOrUpgrade')}</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
    </>
  );
};

export default Step1;
