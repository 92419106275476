import moment from 'moment';
import React, { useEffect, useState } from 'react';

const UnlimitedPlanCounter: React.FunctionComponent<any> = () => {
  // Global Websites
  let counterStartDate = '2022/09/07';
  let counterStartNumber = 1981354598;
  let counterRate = 9.008;

  const [unlimitedPlanMaxDomains, setUnlimitedPlanMaxDomains] = useState<any>(
    counterStartNumber + moment().diff(moment(counterStartDate), 'seconds') * counterRate,
  );

  const [stats, setStats] = useState('Global');

  const toggleStats = () => {
    switch (stats) {
      case 'Global':
        setStats('Squarespace');
        counterStartDate = '2022/09/07';
        counterStartNumber = 2896221;
        counterRate = 0.001;
        break;
      case 'Squarespace':
        setStats('SquareKicker');
        counterStartDate = '2022/09/07';
        counterStartNumber = 8905;
        counterRate = 0.000186;
        break;
      case 'SquareKicker':
        setStats('Global');
        counterStartDate = '2022/09/07';
        counterStartNumber = 1981354598;
        counterRate = 9.008;
        break;
    }
    setUnlimitedPlanMaxDomains(counterStartNumber + moment().diff(moment(counterStartDate), 'seconds') * counterRate);
  };

  useEffect(() => {
    if (stats === 'Global') {
      let interval = setInterval(() => {
        setUnlimitedPlanMaxDomains(
          counterStartNumber + moment().diff(moment(counterStartDate), 'seconds') * counterRate,
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [stats, counterStartDate, counterStartNumber, counterRate]);

  return (
    <span style={{ cursor: 'pointer' }} title={`Total Active ${stats} Websites`} onClick={toggleStats}>
      {Math.ceil(unlimitedPlanMaxDomains).toLocaleString()}
    </span>
  );
};

export default UnlimitedPlanCounter;
