import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { stripePromise } from 'stripe';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Card, CardBody, Button, Row, Col, FormGroup, Label, Alert, Input, Collapse } from 'reactstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Select from 'components/Select';

import { countryCodes, PLAN_TYPE } from 'consts';
import { ME } from 'graphql/queries/me';

import { CREATE_STRIPE_SUBSCRIPTION } from 'graphql/mutations/createStripeSubscription';
import { UPDATE_STRIPE_SUBSCRIPTION } from 'graphql/mutations/updateStripeSubscription';
import { UPDATE_PAYMENT_METHOD } from 'graphql/mutations/updatePaymentMethod';

import NewSubscriptionDetails from '../NewSubscriptionDetails/NewSubscriptionDetails';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#262626',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#d05a3c',
    },
  },
};

const StripeForm: React.FunctionComponent<{
  previousStep: any;
  nextStep: any;
  subscription: any;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const elements = useElements();
  const stripe = useStripe();

  const [createStripeSubscription] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  const [updateStripeSubscription] = useMutation(UPDATE_STRIPE_SUBSCRIPTION);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);

  const { data: meData, loading } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const currentSubscription = history?.location?.state?.currentSubscription;

  const [existingPaymentMethod, setExistingPaymentMethod] = useState<any>(null);

  const [newCard, setNewCard] = useState(false);
  const toggleNewCard = () => {
    if (!newCard) setExistingPaymentMethod(null);
    setNewCard(!newCard);
  };

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCVCComplete, setCardCVCComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [couponCode, setCouponCode] = useState('');
  const [validCouponCode, setValidCouponCode] = useState('');
  const [couponCodeDiscount, setCouponCodeDiscount] = useState<number | null>(null);
  const [couponCodeValid, setCouponCodeValid] = useState<boolean | null>(null);

  if (loading || !meData || !stripe || !elements) return null;

  if (meData.me.stripePaymentMethods?.length > 0 && existingPaymentMethod === null && !newCard) {
    setExistingPaymentMethod(meData.me.stripePaymentMethods.slice(-1).pop());
  }

  // prettier-ignore
  const cardBrand: any = {
      visa: <img src={require('assets/img/payment-method-icons/visa.svg')} alt="Visa" />,
      mastercard: <img src={require('assets/img/payment-method-icons/mastercard.svg')} alt="Mastercard" />,
      amex: <img src={require('assets/img/payment-method-icons/amex.svg')} alt="Amex" />,
      jcb: <img src={require('assets/img/payment-method-icons/jcb.svg')} alt="JCB" />,
      generic: <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />,
    };

  const initialValues = {
    fullName: '',
    address: '',
    city: '',
    postalCode: '',
    country: undefined,
    cardNumber: '',
    expiry: '',
    cvc: '',
    paymentForm: '',
  };

  const applyCouponCode = () => {
    if (couponCode === 'SKCIRCLE20') {
      setValidCouponCode('SKCIRCLE20');
      setCouponCodeValid(true);
      setCouponCodeDiscount(0.2);
    } else if (couponCode === 'SKFRIENDS15' && props.subscription.plan.type !== PLAN_TYPE.AGENCY) {
      setValidCouponCode('SKFRIENDS15');
      setCouponCodeValid(true);
      setCouponCodeDiscount(0.2);
    } else {
      setCouponCodeValid(false);
      setCouponCodeDiscount(null);
    }
  };

  const onSubmitHandler = async (values: any, { resetForm, setSubmitting }: any) => {
    toast.dismiss();
    setErrorMessage(null);

    let paymentMethodData: any = null;

    if (existingPaymentMethod) {
      paymentMethodData = { paymentMethod: existingPaymentMethod };
    } else {
      const cardElement: any = elements.getElement(CardNumberElement);
      paymentMethodData = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.fullName,
          email: meData.me.email,
          address: {
            line1: values.address,
            city: values.city,
            postal_code: values.postalCode,
            country: values.country,
          },
        },
      });
    }

    if (paymentMethodData?.error) {
      setErrorMessage(paymentMethodData.error.message);
      resetForm({ values });
      return;
    } else if (currentSubscription) {
      // Update Subscription
      await updateStripeSubscription({
        variables: {
          planId: props.subscription.plan.id,
          billingCycle: props.subscription.billingCycle,
          paymentMethodId: paymentMethodData.paymentMethod.id,
          coupon: validCouponCode,
          currentSubscriptionId: currentSubscription?.id,
        },
      }).then(
        async (success) => {
          await updatePaymentMethod({
            variables: {
              subscriptionId: success.data.updateStripeSubscription.id,
              paymentMethod: paymentMethodData.paymentMethod,
            },
          });
          props.nextStep({
            planUpgraded: true,
            plan: success.data.updateStripeSubscription.plan,
            website: success.data.updateStripeSubscription.websites[0],
            subscription: success.data.updateStripeSubscription,
          });
        },
        (error) => {
          if (error?.graphQLErrors[0]) {
            setErrorMessage(error?.graphQLErrors[0].message);
          } else {
            graphQLErrorHandler(error);
          }
          resetForm({ values });
        },
      );
    } else {
      // New Subscription
      await createStripeSubscription({
        variables: {
          planId: props.subscription.plan.id,
          billingCycle: props.subscription.billingCycle,
          paymentMethodId: paymentMethodData.paymentMethod.id,
          coupon: validCouponCode,
        },
      }).then(
        async (success) => {
          await updatePaymentMethod({
            variables: {
              subscriptionId: success.data.createStripeSubscription.id,
              paymentMethod: paymentMethodData.paymentMethod,
            },
          });

          const { stripeSubscription, user } = success.data.createStripeSubscription;
          // @ts-ignore
          tap('conversion', stripeSubscription.customer, stripeSubscription.latest_invoice.total / 100, {
            customer_id: user.id,
          });

          props.nextStep({
            plan: success.data.createStripeSubscription.plan,
            website: success.data.createStripeSubscription.websites[0],
            subscription: success.data.createStripeSubscription,
          });
        },
        (error) => {
          if (error?.graphQLErrors[0]) {
            setErrorMessage(error?.graphQLErrors[0].message);
          } else {
            graphQLErrorHandler(error);
          }
          resetForm({ values });
        },
      );
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};

        if (existingPaymentMethod) return errors;

        if (!values.fullName) {
          errors.fullName = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.address) {
          errors.address = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.city) {
          errors.city = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.postalCode) {
          errors.postalCode = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.country) {
          errors.country = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (!cardNumberComplete) {
          errors.cardNumber = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (!cardExpiryComplete) {
          errors.expiry = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (!cardCVCComplete) {
          errors.cvc = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        return errors;
      }}
      onSubmit={onSubmitHandler}
    >
      {({ dirty, submitCount, errors, isSubmitting }) => (
        <Form>
          <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
            <div className="stripe-payment-form">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <h3>{t('newSubscription.paymentFormPaymentInformation')}</h3>
                      <div style={{ clear: 'both', paddingTop: '20px' }}></div>

                      {meData.me.stripePaymentMethods?.length > 0 && (
                        <Collapse isOpen={!newCard}>
                          <p data-test="payment-use-existing-card">
                            <b>{t('newSubscription.paymentFormUseExistingCard')}</b>
                          </p>

                          {meData.me.stripePaymentMethods.map((stripePaymentMethod: any) => {
                            return (
                              <div
                                className={
                                  existingPaymentMethod?.id === stripePaymentMethod.id
                                    ? 'payment-method selected'
                                    : 'payment-method'
                                }
                                key={stripePaymentMethod.id}
                                onClick={() => setExistingPaymentMethod(stripePaymentMethod)}
                              >
                                <p>
                                  {cardBrand[stripePaymentMethod.card.brand] || (
                                    <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />
                                  )}{' '}
                                  •••• {stripePaymentMethod.card.last4}
                                </p>
                                <p>
                                  {t('newSubscription.paymentFormExpiry')}{' '}
                                  {stripePaymentMethod.card.exp_month.toString().padStart(2, '0')}/
                                  {stripePaymentMethod.card.exp_year}
                                </p>
                                <p className="cardholder-name">{stripePaymentMethod.billing_details.name}</p>
                              </div>
                            );
                          })}
                        </Collapse>
                      )}

                      <Collapse isOpen={!meData.me.stripePaymentMethods?.length || newCard}>
                        <FormGroup className={errors.fullName !== undefined ? 'has-danger' : ''}>
                          <Label for="fullName">{t('newSubscription.paymentFormFullName')}</Label>
                          <Field type="text" name="fullName" data-test="fullName" as={Input} />
                          <ErrorMessage
                            name="fullName"
                            component={() => <p className="text-danger">{errors.fullName}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="address">{t('newSubscription.paymentFormAddress')}</Label>
                          <Field name="address" data-test="address" as={Input} />
                          <ErrorMessage
                            name="address"
                            component={() => <p className="text-danger">{errors.address}</p>}
                          />
                        </FormGroup>

                        <Row style={{ padding: '0 15px' }}>
                          <Col
                            md="6"
                            className="form-split-input"
                            style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}
                          >
                            <FormGroup className={errors.city !== undefined ? 'has-danger' : ''}>
                              <Label for="city">{t('newSubscription.paymentFormCity')}</Label>
                              <Field name="city" data-test="city" as={Input} />
                              <ErrorMessage
                                name="city"
                                component={() => <p className="text-danger">{errors.city}</p>}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ padding: 0 }}>
                            <FormGroup className={errors.postalCode !== undefined ? 'has-danger' : ''}>
                              <Label for="postalCode">{t('newSubscription.paymentFormPostalCode')}</Label>
                              <Field name="postalCode" data-test="postalCode" as={Input} />
                              <ErrorMessage
                                name="postalCode"
                                component={() => <p className="text-danger">{errors.postalCode}</p>}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup className={errors.country !== undefined ? 'has-danger' : ''}>
                          <Label for="country">{t('newSubscription.paymentFormCountry')}</Label>
                          <Select className="form-control" name="country" data-test="country">
                            <option value={undefined} hidden></option>
                            {Object.keys(countryCodes).map((countryCode: any) => (
                              <option key={countryCode} value={countryCode}>
                                {countryCodes[countryCode]}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="country"
                            component={() => <p className="text-danger">{errors.country}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="cardNumber">{t('newSubscription.paymentFormCardNumber')}</Label>
                          <CardNumberElement
                            id="cardNumber"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardNumberComplete(e.complete);
                            }}
                          />
                          <ErrorMessage
                            name="cardNumber"
                            component={() => <p className="text-danger">{errors.cardNumber}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="expiry">{t('newSubscription.paymentFormCardExpiry')}</Label>
                          <CardExpiryElement
                            id="expiry"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardExpiryComplete(e.complete);
                            }}
                          />
                          <ErrorMessage
                            name="expiry"
                            component={() => <p className="text-danger">{errors.expiry}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="cvc">{t('newSubscription.paymentFormCardCVC')}</Label>
                          <CardCvcElement
                            id="cvc"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardCVCComplete(e.complete);
                            }}
                          />
                          <ErrorMessage name="cvc" component={() => <p className="text-danger">{errors.cvc}</p>} />
                        </FormGroup>
                      </Collapse>

                      {meData.me.stripePaymentMethods?.length > 0 && (
                        <Button
                          data-test="payment-new-card-button"
                          className="btn-round float-right"
                          color={newCard ? 'ocean' : 'success'}
                          onClick={toggleNewCard}
                        >
                          {newCard ? t('common.cancelButton') : t('dashboard.subscriptionDetailsPaymentMethodNewCard')}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <NewSubscriptionDetails
                    subscription={props.subscription}
                    couponCode={validCouponCode}
                    discount={couponCodeDiscount}
                  />

                  <Row style={{ marginTop: '30px' }}>
                    <Col md="6">
                      <FormGroup
                        className={couponCodeValid === null ? '' : couponCodeValid ? 'has-success' : 'has-danger'}
                      >
                        <Input
                          data-test="apply-coupon-input-field"
                          name="couponCode"
                          placeholder={t('newSubscription.paymentFormCouponCode')}
                          value={couponCode}
                          onChange={(e: any) => {
                            if (/^[A-Za-z0-9]*$/.test(e.target.value)) {
                              setCouponCodeValid(null);
                              setCouponCode(e.target.value.toUpperCase());
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Button
                          data-test="apply-coupon-button"
                          style={{ marginTop: '0' }}
                          className="btn-round no-hide"
                          color="ocean"
                          onClick={applyCouponCode}
                        >
                          {t('newSubscription.paymentFormCouponCodeApply')}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>

                  {couponCodeValid !== null && (
                    <p data-test="coupon-message" className={couponCodeValid ? 'text-success' : 'text-danger'}>
                      {couponCodeValid
                        ? t('newSubscription.paymentFormCouponCodeValid')
                        : t('newSubscription.paymentFormCouponCodeInvalid')}
                    </p>
                  )}

                  <ErrorMessage
                    name="paymentForm"
                    component={() => (
                      <Alert data-test="payment-failed-error" color="danger">
                        {errors.paymentForm}
                      </Alert>
                    )}
                  />

                  {errorMessage && (
                    <Alert color="danger">
                      <p>
                        <b>{t('newSubscription.paymentFormPaymentFailed')}</b>
                      </p>
                      <p data-test="card-payment-failed-error">{errorMessage}</p>
                    </Alert>
                  )}

                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      className="btn-round btn-lg btn-full-width no-hide"
                      color="purple"
                      data-test="payment-details-pay-now-btn"
                    >
                      <i className="fa fa-lock"></i>
                      {isSubmitting
                        ? t('newSubscription.paymentFormProcessingPayment')
                        : t('newSubscription.paymentFormPay')}
                    </Button>
                  </div>

                  <p className="text-small">
                    <Trans i18nKey="newSubscription.paymentFormTermsAndConditions">
                      By purchasing, you accept the
                      <a href="https://squarekicker.com/terms" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                      </a>
                      and acknowledge reading the
                      <a href="https://squarekicker.com/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      . All information is securely submitted through Stripe Payments, your card details we be saved for
                      future purchases and subscription renewals.
                    </Trans>
                  </p>
                </CardBody>
              </Card>
            </div>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const PaymentForm: React.FunctionComponent<{
  previousStep: any;
  nextStep: any;
  subscription: any;
}> = (props) => {
  const { t } = useTranslation();

  stripePromise.catch(() => {
    toast.dismiss();
    graphQLErrorHandler(null);
  });

  return (
    <Elements stripe={stripePromise}>
      <StripeForm previousStep={props.previousStep} nextStep={props.nextStep} subscription={props.subscription} />
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <Button
              data-test="payment-details-back-btn"
              className="btn-round no-hide"
              color="ocean"
              onClick={props.previousStep}
            >
              {t('common.back')}
            </Button>
          </div>
        </Col>
      </Row>
    </Elements>
  );
};

export default PaymentForm;
