import { gql } from '@apollo/client';

import { WEBSITE_FIELDS, WEBSITE_RELATIONS } from '../fragments/website';

export const SQSP_OAUTH_CONNECT_WEBSITE = gql`
  ${WEBSITE_FIELDS}
  ${WEBSITE_RELATIONS}
  mutation SQSPOAuthConnectWebsite($subscriptionId: String!, $websiteId: String, $sqspAuthCode: String!) {
    SQSPOAuthConnectWebsite(subscriptionId: $subscriptionId, websiteId: $websiteId, sqspAuthCode: $sqspAuthCode) {
      ...WebsiteFields
      ...WebsiteRelations
    }
  }
`;
