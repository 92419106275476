import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const UPDATE_STRIPE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  mutation UpdateStripeSubscription(
    $currentSubscriptionId: String!
    $planId: String!
    $billingCycle: BillingCycle!
    $paymentMethodId: String!
    $coupon: String
  ) {
    updateStripeSubscription(
      planId: $planId
      billingCycle: $billingCycle
      paymentMethodId: $paymentMethodId
      coupon: $coupon
      currentSubscriptionId: $currentSubscriptionId
    ) {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
