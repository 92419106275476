import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row } from 'reactstrap';

import moment from 'moment';

import { BILLING_CYCLE, PLAN_TYPE } from 'consts';
import { UPCOMING_STRIPE_INVOICE } from 'graphql/queries/upcomingStripeInvoice';

import { UPDATE_STRIPE_SUBSCRIPTION_AUTO_RENEW } from 'graphql/mutations/updateStripeSubscriptionAutoRenew';
import { graphQLErrorHandler } from 'graphql/apollo';
import Modal from 'components/Modal';

import UpdatePaymentMethodForm from './UpdatePaymentMethodForm';
import { Link, useHistory } from 'react-router-dom';
import UnlimitedPlanCounter from 'components/UnlimitedPlanCounter';

const SubscriptionDetails: React.FunctionComponent<{
  subscription: any;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  // prettier-ignore
  const cardBrand: any = {
    visa: <img src={require('assets/img/payment-method-icons/visa.svg')} alt="Visa" />,
    mastercard: <img src={require('assets/img/payment-method-icons/mastercard.svg')} alt="Mastercard" />,
    amex: <img src={require('assets/img/payment-method-icons/amex.svg')} alt="Amex" />,
    jcb: <img src={require('assets/img/payment-method-icons/jcb.svg')} alt="JCB" />,
    generic: <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />,
  };

  const { data } = useQuery(UPCOMING_STRIPE_INVOICE, {
    fetchPolicy: 'network-only',
    variables: {
      subscriptionId: props.subscription.id,
    },
    skip:
      !props.subscription.autoRenew ||
      props.subscription.plan.type === PLAN_TYPE.FREE_TRIAL ||
      !props.subscription.stripeSubscription,
  });

  const [updateStripeSubscriptionAutoRenew] = useMutation(UPDATE_STRIPE_SUBSCRIPTION_AUTO_RENEW);

  const [disableAutoRenewModalIsOpen, setdisableAutoRenewModalIsOpen] = useState(false);

  const disableAutoRenewModalToggle = () => {
    setdisableAutoRenewModalIsOpen(!disableAutoRenewModalIsOpen);
  };

  const changeAutoRenew = async (autoRenew: boolean) => {
    await updateStripeSubscriptionAutoRenew({
      variables: {
        currentSubscriptionId: props.subscription.id,
        autoRenew,
      },
    }).then(
      async (success) => {},
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  const [editPaymentMethodModalIsOpen, setEditPaymentMethodModalIsOpen] = useState(false);

  const editPaymentMethodModalToggle = () => {
    if (editPaymentMethodModalIsOpen) {
      history.push('/subscriptions');
      setEditPaymentMethodSubmitTrigger({ value: false });
    }
    setEditPaymentMethodModalIsOpen(!editPaymentMethodModalIsOpen);
  };

  const [editPaymentMethodSubmitTrigger, setEditPaymentMethodSubmitTrigger] = useState({
    value: false,
  });

  const updatePaymentMethod = async () => {
    setEditPaymentMethodSubmitTrigger({ value: true });
  };

  useEffect(() => {
    if (editPaymentMethodSubmitTrigger.value) {
      setEditPaymentMethodSubmitTrigger({ value: false });
    }
  }, [editPaymentMethodSubmitTrigger.value]);

  return (
    <>
      <h3>{t('dashboard.subscriptionSectionHeader')}</h3>

      {/* Plan */}
      <p>
        <b>{t('dashboard.subscriptionDetailsPlan')}:</b> {props.subscription.plan.name}
      </p>

      {/* Active Websites */}
      {(props.subscription.plan.type === PLAN_TYPE.AGENCY || props.subscription.plan.type === PLAN_TYPE.DESIGNER) && (
        <p>
          <b>{t('dashboard.subscriptionDetailsActiveWebsites')}:</b>{' '}
          {props.subscription.websites.filter((website: any) => website.active).length}/
          {props.subscription.plan.id === '5f8621e1efceda001ac6d8c4' ? (
            <UnlimitedPlanCounter />
          ) : (
            props.subscription.plan.maxDomains
          )}
        </p>
      )}

      {/* Billing Cycle */}
      {props.subscription.stripeSubscription &&
        props.subscription.stripeSubscription?.status !== 'canceled' &&
        props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && (
          <p>
            <b>{t('dashboard.subscriptionDetailsBillingCycle')}:</b>{' '}
            {props.subscription.autoRenew ? (
              <>
                {props.subscription.stripeSubscription?.plan.metadata.billingCycle === BILLING_CYCLE.ANNUAL
                  ? t('dashboard.subscriptionDetailsBillingCycleAnnual')
                  : t('dashboard.subscriptionDetailsBillingCycleMonthly')}
              </>
            ) : (
              <span className="text-danger">
                {props.subscription.stripeSubscription?.status === 'canceled'
                  ? t('dashboard.subscriptionDetailsExpired')
                  : t('dashboard.subscriptionDetailsAutoRenewDisabled')}
              </span>
            )}
          </p>
        )}

      {/* Free Trial/Unlimited Expiry */}
      {((props.subscription.plan.type === PLAN_TYPE.FREE_TRIAL && props.subscription.expiryDate) ||
        !props.subscription.stripeSubscription) && (
        <p>
          <b>
            {props.subscription.stripeSubscription?.status === 'canceled'
              ? t('dashboard.subscriptionDetailsExpired')
              : t('dashboard.subscriptionDetailsExpires')}
            :
          </b>{' '}
          {props.subscription.stripeSubscription?.status === 'canceled' ? (
            <span className="text-danger">
              {moment.utc(props.subscription.expiryDate).local().format('MMM D, YYYY')}
            </span>
          ) : props.subscription.expiryDate ? (
            moment.utc(props.subscription.expiryDate).add(1, 'day').local().format('MMM D, YYYY')
          ) : (
            t('dashboard.subscriptionDetailsNoExpiry')
          )}
        </p>
      )}

      {/* Auto Renew */}
      {props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && props.subscription.stripeSubscription && (
        <p>
          <b>
            {props.subscription.autoRenew
              ? t('dashboard.subscriptionDetailsAutoRenew')
              : props.subscription.stripeSubscription?.status === 'canceled'
              ? t('dashboard.subscriptionDetailsExpired')
              : t('dashboard.subscriptionDetailsExpires')}
            :
          </b>{' '}
          {props.subscription.stripeSubscription?.status === 'canceled' ? (
            <span className="text-danger">
              {moment.utc(props.subscription.expiryDate).local().format('MMM D, YYYY')}
            </span>
          ) : (
            moment.utc(props.subscription.expiryDate).add(1, 'day').local().format('MMM D, YYYY')
          )}
        </p>
      )}

      {/* Upcoming Payment */}
      {props.subscription.autoRenew &&
        props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL &&
        props.subscription.stripeSubscription && (
          <p>
            <b>{t('dashboard.subscriptionDetailsUpcomingPayment')}:</b>{' '}
            {data && (
              <>
                <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                <span className="pricing-price">
                  {(data.upcomingStripeInvoice.stripeInvoice.total / 100).toFixed(2)}
                </span>
              </>
            )}
          </p>
        )}

      {/* Payment Method */}
      {props.subscription.autoRenew &&
        props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL &&
        props.subscription.stripeSubscription &&
        props.subscription.stripePaymentMethod && (
          <>
            <p>
              <b>{t('dashboard.subscriptionDetailsPaymentMethod')}:</b>{' '}
            </p>

            <p style={{ paddingLeft: '10px' }} className="text-small">
              {cardBrand[props.subscription.stripePaymentMethod.card.brand] || (
                <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />
              )}{' '}
              •••• {props.subscription.stripePaymentMethod.card.last4}
              {/* Edit Payment Method */}
              <Modal
                isOpen={editPaymentMethodModalIsOpen}
                toggle={editPaymentMethodModalToggle}
                header={t('dashboard.subscriptionDetailsUpdatePaymentMethodTitle')}
                body={
                  <UpdatePaymentMethodForm
                    subscription={props.subscription}
                    submitTrigger={editPaymentMethodSubmitTrigger}
                    modalToggle={editPaymentMethodModalToggle}
                  />
                }
                footer={
                  <>
                    <Button className="btn-round no-hide" color="ocean" onClick={() => editPaymentMethodModalToggle()}>
                      {t('common.cancelButton')}
                    </Button>
                    <Button className="btn-round no-hide" color="success" onClick={() => updatePaymentMethod()}>
                      {t('common.saveButton')}
                    </Button>
                  </>
                }
              />
              <Link
                to={'/subscriptions/' + props.subscription.id + '/update-payment-details'}
                className="a text-small"
                style={{ marginLeft: '1rem' }}
                onClick={() => editPaymentMethodModalToggle()}
              >
                {t('common.edit')}
              </Link>
              {/* End Edit Payment Method */}
            </p>
            <p style={{ paddingLeft: '10px' }} className="text-small">
              {t('newSubscription.paymentFormExpiry')}{' '}
              {props.subscription.stripePaymentMethod.card.exp_month.toString().padStart(2, '0')}/
              {props.subscription.stripePaymentMethod.card.exp_year}
            </p>
            <p style={{ paddingLeft: '10px' }} className="text-small cardholder-name">
              {props.subscription.stripePaymentMethod.billing_details.name}
            </p>
          </>
        )}

      {/* Cancel Auto Renew */}
      {props.subscription.stripeSubscription?.status !== 'canceled' &&
        props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && (
          <div style={{ marginTop: '20px' }}>
            {props.subscription.autoRenew && (
              <>
                <Modal
                  isOpen={disableAutoRenewModalIsOpen}
                  toggle={disableAutoRenewModalToggle}
                  header={t('subscriptions.disableAutoRenew')}
                  body={
                    <>
                      <Row>
                        <Col sm="12">
                          <p>{t('subscriptions.disableAutoRenewText')}</p>
                        </Col>
                      </Row>
                    </>
                  }
                  footer={
                    <>
                      <Button className="btn-round no-hide" color="ocean" onClick={() => disableAutoRenewModalToggle()}>
                        {t('common.cancelButton')}
                      </Button>
                      <Button
                        className="btn-round no-hide"
                        color="danger"
                        onClick={() => {
                          changeAutoRenew(false);
                          disableAutoRenewModalToggle();
                        }}
                      >
                        {t('common.confirmButton')}
                      </Button>
                    </>
                  }
                />
                <Button className="btn-round" color="danger" onClick={() => disableAutoRenewModalToggle()}>
                  {t('subscriptions.disableAutoRenew')}
                </Button>
              </>
            )}

            {props.subscription?.autoRenew === false && (
              <Button className="btn-round" color="success" onClick={() => changeAutoRenew(true)}>
                {t('subscriptions.enableAutoRenew')}
              </Button>
            )}
          </div>
        )}
    </>
  );
};

export default SubscriptionDetails;
