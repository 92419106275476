import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FullscreenNavbar from 'components/Navbars/FullscreenNavbar';
import NewSubscription from 'views/NewSubscription';

const FullscreenLayout: React.FunctionComponent = () => {
  return (
    <div className="wrapper fullscreen-layout">
      <div className="main-panel">
        <FullscreenNavbar />
        <Switch>
          <Route path="/get-started" component={NewSubscription} />
        </Switch>
      </div>
    </div>
  );
};

export default FullscreenLayout;
