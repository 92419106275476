import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const UPDATE_STRIPE_SUBSCRIPTION_AUTO_RENEW = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  mutation UpdateStripeSubscriptionAutoRenew($currentSubscriptionId: String!, $autoRenew: Boolean!) {
    updateStripeSubscriptionAutoRenew(currentSubscriptionId: $currentSubscriptionId, autoRenew: $autoRenew) {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
