import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const CREATE_FREE_TRIAL = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  mutation CreateFreeTrial {
    createFreeTrial {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
