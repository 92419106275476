import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input, Label, Button, Alert } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { toast } from 'react-toastify';

import { ME } from 'graphql/queries/me';
import { LOGIN } from 'graphql/mutations/login';
import { UPDATE_USER } from 'graphql/mutations/updateUser';

const ChangePasswordForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const { data, loading } = useQuery(ME);
  const [login] = useMutation(LOGIN);
  const [updateUser] = useMutation(UPDATE_USER);

  const [currentPasswordError, setCurrentPasswordError] = useState('');

  if (loading || !data) return null;

  const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  };

  const onSubmitHandler = async (values: any, { resetForm }: any) => {
    toast.dismiss();

    // Reauthenticate to Validate Current Password
    const user = {
      identifier: data.me.email,
      password: values.password,
    };

    // Remove current token to allow login request
    localStorage.removeItem('token');
    await login({ variables: { user } }).then(
      async (success) => {
        // Store new token
        localStorage.setItem('token', success.data.login.jwt);

        let userData = {
          password: values.newPassword,
        };

        await updateUser({
          variables: {
            user: {
              where: {
                id: data.me.id,
              },
              data: userData,
            },
          },
        }).then(
          (success) => {
            // Logout and display change password success message
            history.replace('/logout?changePasswordSuccess');
          },
          (error) => {
            graphQLErrorHandler(error);
            resetForm({ values });
          },
        );
      },
      (error) => {
        // Reauthenticate failed
        setCurrentPasswordError(t('formValidation.currentPasswordError'));
        resetForm({ values });
      },
    );
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};

        if ((values.newPassword || values.confirmPassword) && !values.password) {
          errors.password = t('formValidation.forgotSomething');
        }

        if ((values.password || values.confirmPassword) && !values.newPassword) {
          errors.newPassword = t('formValidation.forgotSomething');
        }

        if ((values.password || values.newPassword) && !values.confirmPassword) {
          errors.confirmPassword = t('formValidation.forgotSomething');
        }

        if (values.newPassword && values.newPassword !== values.confirmPassword) {
          errors.confirmPassword = t('formValidation.passwordsDontMatch');
        }

        return errors;
      }}
      onSubmit={onSubmitHandler}
    >
      {({ dirty, submitCount, errors, resetForm }) => (
        <Form>
          <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
            <Row>
              <Col sm="12">
                <h5 data-test="account-change-password-title">{t('account.changePasswordSectionHeader')}</h5>
                <FormGroup className={errors.password !== undefined ? 'has-danger' : ''}>
                  <Label data-test="account-current-password-label" for="password">
                    {t('account.currentPassword')}
                  </Label>
                  <Field
                    data-test="account-current-password-input"
                    type="password"
                    name="password"
                    autoComplete="off"
                    as={Input}
                  />
                  <ErrorMessage
                    name="password"
                    component={() => (
                      <p data-test="account-current-password-error" className="text-danger">
                        {errors.password}
                      </p>
                    )}
                  />
                  {currentPasswordError && (
                    <Alert color="danger">
                      <p>{currentPasswordError}</p>
                    </Alert>
                  )}
                </FormGroup>
                <FormGroup className={errors.newPassword !== undefined ? 'has-danger' : ''}>
                  <Label data-test="account-new-password-label" for="newPassword">
                    {t('account.newPassword')}
                  </Label>
                  <Field data-test="account-new-password-input" type="password" name="newPassword" as={Input} />
                  <ErrorMessage
                    name="newPassword"
                    component={() => (
                      <p data-test="account-new-password-error" className="text-danger">
                        {errors.newPassword}
                      </p>
                    )}
                  />
                </FormGroup>
                <FormGroup className={errors.confirmPassword !== undefined ? 'has-danger' : ''}>
                  <Label data-test="account-confirm-password-label" for="confirmPassword">
                    {t('account.confirmPassword')}
                  </Label>
                  <Field data-test="account-confirm-password-input" type="password" name="confirmPassword" as={Input} />
                  <ErrorMessage
                    name="confirmPassword"
                    component={() => (
                      <p data-test="account-confirm-password-error" className="text-danger">
                        {errors.confirmPassword}
                      </p>
                    )}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button
                    data-test="account-change-password-button"
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    type="submit"
                    className="btn-round"
                    color="primary"
                  >
                    {t('account.changePasswordButton')}
                  </Button>
                  <Button
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    onClick={() => resetForm({ values: initialValues })}
                    data-test="account-cancel-password-button"
                    className="btn-round"
                    color="danger"
                  >
                    {t('common.cancelButton')}
                  </Button>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
