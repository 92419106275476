import { BILLING_CYCLE, PLAN_TYPE } from 'consts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';

const PricingCard: React.FunctionComponent<{
  plan: any;
  billingCycle: BILLING_CYCLE;
  nextStep: any;
  currentPlan: boolean;
  downgrade: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <Col lg="3">
      <Card
        className={
          props.downgrade && !(history?.location?.state?.reactivate && props.currentPlan)
            ? 'card-pricing disabled'
            : 'card-pricing'
        }
      >
        <CardHeader></CardHeader>
        <CardBody>
          <div
            data-test={'pricing-card-sash-' + props.plan.name.toLowerCase()}
            className={
              props.plan.type !== PLAN_TYPE.FREE_TRIAL && props.billingCycle === BILLING_CYCLE.ANNUAL
                ? 'sash plan-bgcolor-' + props.plan.type
                : 'sash hide-hidden'
            }
          >
            {t('newSubscription.step1SaveAnnuallySash')}
          </div>

          <h2
            data-test={'pricing-card-plan-name-' + props.plan.name.toLowerCase()}
            className={'plan-color-' + props.plan.type}
          >
            {props.plan.name}
          </h2>
          <h3>
            {props.plan.type === PLAN_TYPE.FREE_TRIAL && (
              <>
                <span></span>
                <span className="pricing-free">{t('newSubscription.step1Free')}</span>
                <span></span>
              </>
            )}
            {props.plan.type !== PLAN_TYPE.FREE_TRIAL && (
              <>
                <span className="pricing-currency">{t('newSubscription.step1CurrencyUSD')}</span>
                <span data-test={'pricing-card-price-' + props.plan.name.toLowerCase()} className="pricing-price">
                  {props.billingCycle === BILLING_CYCLE.ANNUAL
                    ? props.plan.annualPrice / 100 / 12
                    : props.plan.monthlyPrice / 100}
                </span>
                <span className="pricing-month">{t('newSubscription.step1PerMonth')}</span>
              </>
            )}
          </h3>

          <p data-test={'pricing-card-billing-cycle-' + props.plan.name.toLowerCase()} className="billing-cycle">
            {props.plan.type === PLAN_TYPE.FREE_TRIAL && t('newSubscription.step1FreeTrial')}
            {props.plan.type !== PLAN_TYPE.FREE_TRIAL &&
              (props.billingCycle === BILLING_CYCLE.ANNUAL
                ? t('newSubscription.step1BilledAnnually')
                : t('newSubscription.step1BilledMonthly'))}
          </p>
          <hr />
          <div
            data-test={'pricing-card-plan-description-' + props.plan.name.toLowerCase()}
            className="plan-description"
            dangerouslySetInnerHTML={{ __html: props.plan.description }}
          />
          <Button
            className="btn-round no-hide btn-wide"
            data-test={'buy-now-button-' + props.plan.name.toLowerCase()}
            color={'plan-color-' + props.plan.type}
            size="lg"
            onClick={() => {
              props.downgrade && !(history?.location?.state?.reactivate && props.currentPlan)
                ? history.push('/help')
                : props.nextStep(props.plan);
            }}
            disabled={
              !(!props.currentPlan && props.downgrade && props.plan.type !== PLAN_TYPE.FREE_TRIAL) &&
              props.downgrade &&
              !(history?.location?.state?.reactivate && props.currentPlan)
            }
          >
            {props.currentPlan && t('newSubscription.step1CurrentPlan')}
            {!props.currentPlan &&
              props.downgrade &&
              (props.plan.type === PLAN_TYPE.FREE_TRIAL
                ? t('newSubscription.step1TrialStarted')
                : t('newSubscription.step1ContactUs'))}
            {!props.currentPlan &&
              !props.downgrade &&
              (props.plan.type === PLAN_TYPE.FREE_TRIAL
                ? t('newSubscription.step1StartTrial')
                : t('newSubscription.step1BuyNow'))}
          </Button>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Col>
  );
};

export default PricingCard;
