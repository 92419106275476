import { Input } from 'reactstrap';
import { useField } from 'formik';
import React from 'react';

const Select: React.FunctionComponent<any> = (props) => {
  const [field] = useField(props);
  return (
    <Input type="select" {...field} {...props}>
      {props.children}
    </Input>
  );
};

export default Select;
