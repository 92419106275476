import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  mutation CreateStripeSubscription(
    $planId: String!
    $billingCycle: BillingCycle!
    $paymentMethodId: String!
    $coupon: String
  ) {
    createStripeSubscription(
      planId: $planId
      billingCycle: $billingCycle
      paymentMethodId: $paymentMethodId
      coupon: $coupon
    ) {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
