import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Card, CardBody, Alert } from 'reactstrap';

import { WEBSITE } from 'graphql/queries/website';
import { PLAN_TYPE } from 'consts';

import SQSPConnectWebsiteButton from 'views/Dashboard/components/Subscriptions/components/SQSPConnectWebsiteButton';

const Step3: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();

  const plan = history.location?.state?.plan;
  const planUpgraded = history.location?.state?.planUpgraded;

  const { data, loading } = useQuery(WEBSITE, {
    variables: { id: history.location.state?.website?.id },
    skip: !history.location.state?.website?.id,
  });

  if (loading) return null;

  const nextStep = () => {
    if (plan.type === PLAN_TYPE.DESIGNER || plan.type === PLAN_TYPE.AGENCY) {
      history.replace('/dashboard');
    } else if (data?.website?.domain) {
      window.open(`https://${data.website.domain}/config`, '_blank');
      history.replace('/dashboard');
    } else if (data?.website?.id) {
      history.replace(`/subscription/${history.location.state.subscription.id}/website/${data.website.id}/settings`);
    } else {
      history.replace(`/subscription/${history.location.state.subscription.id}/website/connect`);
    }
  };

  return (
    <>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-center align-items-center">
            <h2>{t('newSubscription.step3Title')}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12"></Col>
      </Row>
      <div className="d-flex justify-content-center">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <div className="d-flex justify-content-center align-items-center payment-success">
                      <Alert color="success">
                        <div className="d-flex justify-content-center align-items-center">
                          <h3 data-test="step-3-success-message-title">
                            {plan.type === PLAN_TYPE.FREE_TRIAL
                              ? t('newSubscription.step3FreeTrialCreated')
                              : t('newSubscription.step3PaymentSuccess')}
                          </h3>
                        </div>
                        <p>
                          {planUpgraded
                            ? t('newSubscription.step3PlanUpgradedText')
                            : t('newSubscription.step3PaymentSuccessText')}
                        </p>
                      </Alert>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      {(plan.type === PLAN_TYPE.DESIGNER || plan.type === PLAN_TYPE.AGENCY) && (
                        <Button className="btn-round btn-lg no-hide" color="ocean" onClick={nextStep}>
                          {t('newSubscription.step3Continue')}
                        </Button>
                      )}
                      {!(plan.type === PLAN_TYPE.DESIGNER || plan.type === PLAN_TYPE.AGENCY) &&
                        data?.website?.sqspConnected && (
                          <Button className="btn-round btn-lg no-hide" color="ocean" onClick={nextStep}>
                            {t('newSubscription.step3OpenSQSPConfig')}
                          </Button>
                        )}
                      {!(plan.type === PLAN_TYPE.DESIGNER || plan.type === PLAN_TYPE.AGENCY) &&
                        !data?.website?.sqspConnected && (
                          <SQSPConnectWebsiteButton
                            subscription={history.location.state.subscription}
                            website={data?.website}
                          />
                        )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Step3;
