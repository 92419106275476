import { gql } from '@apollo/client';

export const UPCOMING_STRIPE_INVOICE = gql`
  query UpcomingStripeInvoice($subscriptionId: String, $planId: String, $billingCycle: BillingCycle, $coupon: String) {
    upcomingStripeInvoice(
      subscriptionId: $subscriptionId
      planId: $planId
      billingCycle: $billingCycle
      coupon: $coupon
    ) {
      stripeInvoice
    }
  }
`;
