import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { WEBSITE } from 'graphql/queries/website';
import { SUBSCRIPTION } from 'graphql/queries/subscription';

import InstallationCode from './InstallationCode';
import InstallationSuccess from './InstallationSuccess';

import SQSPConnectWebsiteButton from './SQSPConnectWebsiteButton';

const WebsiteSettingsModal: React.FunctionComponent<{
  toggle: any;
  state: any;
}> = (props) => {
  const { t } = useTranslation();
  const { subscriptionId, websiteId } = useParams<{ subscriptionId: string; websiteId: string }>();

  const { data, refetch } = useQuery(WEBSITE, {
    variables: { id: websiteId },
    skip: !websiteId || websiteId === 'connect',
  });

  const { data: subscriptionData } = useQuery(SUBSCRIPTION, {
    variables: { id: subscriptionId },
  });

  const [installationCodeCollapseIsOpen, setInstallationCodeCollapseIsOpen] = useState(true);
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    if (data) {
      if (!data?.website.lastSKRequestDate) {
        props.state.startPolling(5000);
        setIsPolling(true);
      }
      if (isPolling) setInstallationCodeCollapseIsOpen(data?.website.domain && !data?.website.lastSKRequestDate);
    }
  }, [data, props.state, isPolling]);

  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );

  return (
    <Modal className="website-settings-modal" size="lg" isOpen={true} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {data?.website.domain ? t('dashboard.websiteSettingsModalTitle') : t('dashboard.connectWebsiteModalTitle')}
      </ModalHeader>
      <ModalBody>
        {subscriptionData && (
          <SQSPConnectWebsiteButton subscription={subscriptionData.subscription} website={data?.website} />
        )}
        {data?.website.domain && data?.website.sqspConnected && (
          <InstallationCode
            website={data?.website}
            refetch={refetch}
            collapseIsOpen={installationCodeCollapseIsOpen}
            setCollapseIsOpen={setInstallationCodeCollapseIsOpen}
          />
        )}
        {data?.website.lastSKRequestDate && data?.website.sqspConnected && <InstallationSuccess />}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.closeButton')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default WebsiteSettingsModal;
