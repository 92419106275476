import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import DashboardSidebar from 'components/Sidebars/DashboardSidebar';

import Dashboard from 'views/Dashboard';
import Account from 'views/Account';
import SubscriptionsView from 'views/Subscriptions';
import Help from 'views/Help';

const DashboardLayout: React.FunctionComponent = () => {
  return (
    <div className="wrapper dashboard-layout">
      <DashboardSidebar />
      <div className="main-panel">
        <DashboardNavbar />
        <Switch>
          <Route path="/account" component={Account} />
          <Route path="/subscriptions" component={SubscriptionsView} />
          <Route path="/help" component={Help} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </div>
    </div>
  );
};

export default DashboardLayout;
