import React from 'react';
import { Navbar, Nav, Container } from 'reactstrap';

const AuthNavbar: React.FunctionComponent = () => {
  return (
    <Navbar className="navbar-absolute fixed-top navbar-transparent" expand="lg">
      <Container>
        <div className="navbar-wrapper"></div>
        <Nav navbar>
          {/* <NavItem>
            {location.pathname == '/signup' ? (
              <NavLink to="/login" className="nav-link">
                Login
              </NavLink>
            ) : (
              <NavLink to="/signup" className="nav-link">
                Sign Up
              </NavLink>
            )}
          </NavItem> */}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AuthNavbar;
