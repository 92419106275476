import { PLAN_TYPE } from 'consts';
import { websiteActivationsResetDateFunction } from 'functions';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip, Button } from 'reactstrap';
import { GET_STARTED_STEP_1_PATH } from 'routes';

const ActivationsReachedTooltip: React.FunctionComponent<{
  subscription: any;
  tooltipOpen: any;
  setTooltipOpen: any;
  target: any;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Tooltip
      isOpen={props.tooltipOpen === props.target}
      trigger="click"
      className="large-tooltip"
      placement="right"
      autohide={false}
      hideArrow={true}
      target={props.target}
    >
      <p>
        <b>{t('dashboard.subscriptionDetailsWebsiteActivationsReached')}</b>
      </p>
      <p>
        <Trans i18nKey="dashboard.subscriptionDetailsWebsiteActivationsInfo">
          Connecting a new website or restoring an inactive one will count towards your Monthly Activations. This will
          reset on the
          <b>
            {{
              websiteActivationsResetDate: websiteActivationsResetDateFunction(props.subscription)
                .local()
                .format('MMM D, YYYY'),
            }}
          </b>
          .
        </Trans>
      </p>
      <p>
        <b>{t('dashboard.subscriptionDetailsWebsiteActivationsInfoUpgrade')}</b>
      </p>
      {props.subscription.plan.type !== PLAN_TYPE.AGENCY && (
        <Button
          className="btn-round btn-purple"
          onClick={() => {
            history.push(GET_STARTED_STEP_1_PATH + '?selectedPlanId=607e4ebfce210e00b8f31231');
          }}
        >
          {t('dashboard.upgradeToAgency')}
        </Button>
      )}
      {props.subscription.plan.type === PLAN_TYPE.AGENCY && (
        <Button
          className="btn-round btn-success"
          onClick={() => {
            history.push('/help');
          }}
        >
          {t('newSubscription.step1ContactUs')}
        </Button>
      )}
      <Button
        className="btn-round btn-primary"
        onClick={() => {
          props.setTooltipOpen('');
        }}
      >
        {t('common.cancelButton')}
      </Button>
    </Tooltip>
  );
};

export default ActivationsReachedTooltip;
