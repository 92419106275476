import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

import Subscriptions from './components/Subscriptions';

import { Link } from 'react-router-dom';
import { GET_STARTED_STEP_1_PATH } from 'routes';
import { SUBSCRIPTIONS } from 'graphql/queries/subscriptions';
import { useQuery } from '@apollo/client';

import DashboardContent from './components/DashboardContent';

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { data } = useQuery(SUBSCRIPTIONS);

  return (
    <div className="content dashboard-view">
      <Row>
        <Col md="12" className="sk-page-header">
          <div className="sk-page-title">
            <h1>{t('dashboard.pageTitle')}</h1>
          </div>
          <div className="sk-page-actions">
            {data?.subscriptions?.length > 0 && (
              <Link to={GET_STARTED_STEP_1_PATH}>
                <Button
                  id="sk-new-subscription-btn"
                  data-test="new-subscription-button"
                  className="btn-primary btn-round sk-new-subscription-btn"
                >
                  <i className="fa fa-plus" />
                  {t('dashboard.newSubscriptionButton')}
                </Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Subscriptions />
        </Col>
      </Row>
      <DashboardContent />
    </div>
  );
};

export default Dashboard;
