import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { Chip, Divider } from '@mui/material';

import { ReactComponent as SquarespaceLogo } from 'assets/img/sqsp-logo.svg';

const SignupLanding: React.FunctionComponent = () => {
  const history = useHistory();

  return (
    <div className="login-page">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal">
              <img src={require('assets/img/sk-logo-black.png')} data-test="skLogoImage" alt="SquareKicker" />
            </a>
          </div>
          <div className="page-title">
            <h2 data-test="signupLandingPageTitle">{t('signup.createYourAccount')}</h2>
          </div>
          <p style={{ marginBottom: 0 }} className="text-center" data-test="signupLandingPageDescription">
            {t('signup.signUpOption')}
          </p>
          <div className="signup-with-email-btn">
            <Button
              display="flex"
              data-test="continueWithSquarespaceButton"
              onClick={() => history.push('/signup-with-squarespace')}
            >
              <SquarespaceLogo />
              {t('signup.signUpWithSquarespace')}
            </Button>
          </div>
          <div className="auth-divider">
            <Divider>
              <Chip label="OR" />
            </Divider>
          </div>
          <div className="signup-with-email-btn">
            <Button
              display="flex"
              data-test="continueWithEmailButton"
              onClick={() => history.push('/signup-with-email')}
            >
              {t('signup.signUpWithEmail')}
            </Button>
          </div>
          <Row className="auth-form">
            <Col md="auto" className="login-link justify-content-center m-auto" data-test="logInLink">
              <span>
                <Trans i18nKey="signup.logIn">
                  Already a member? <Link to="/login">Log In</Link>
                </Trans>
              </span>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SignupLanding;
