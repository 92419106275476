import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CardBody, Card, Row, Col, Button } from 'reactstrap';

import { SUBSCRIPTIONS } from 'graphql/queries/subscriptions';
import { GET_STARTED_STEP_1_PATH } from 'routes';
import { BILLING_CYCLE, PLAN_TYPE } from 'consts';

import SubscriptionDetails from './components/SubscriptionDetails';
import GetStarted from 'views/Dashboard/components/Subscriptions/components/GetStarted';
import DomainLink from 'components/DomainLink';

const Subscriptions: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();

  const { data, loading } = useQuery(SUBSCRIPTIONS);

  if (loading || !data) return null;

  return (
    <>
      {data.subscriptions.length === 0 && <GetStarted />}
      {Array.from(data.subscriptions)
        .reverse()
        .map((subscription: any) => {
          return (
            <Card key={subscription.id}>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <h2 className="subscriptionTitle">{subscription.plan.name}</h2>
                    {/* Upgrade Subscription */}
                    {((!(
                      subscription.plan.type === PLAN_TYPE.AGENCY &&
                      subscription?.stripeSubscription?.plan?.metadata?.billingCycle === BILLING_CYCLE.ANNUAL
                    ) &&
                      subscription.stripeSubscription?.status !== 'canceled') ||
                      (subscription.plan.type === PLAN_TYPE.FREE_TRIAL &&
                        subscription.stripeSubscription?.status === 'canceled')) && (
                      <Button
                        className="btn-round float-right"
                        style={{ margin: 0 }}
                        color="purple"
                        onClick={() => {
                          history.push(GET_STARTED_STEP_1_PATH, {
                            currentSubscription: subscription,
                          });
                        }}
                      >
                        <i className="fa fa-arrow-circle-up" />
                        {t('common.upgradePlan')}
                      </Button>
                    )}
                    {/* Reactivate Expired Subscription */}
                    {subscription.plan.type !== PLAN_TYPE.FREE_TRIAL &&
                      subscription.stripeSubscription?.status === 'canceled' && (
                        <Button
                          className="btn-round float-right"
                          style={{ margin: 0 }}
                          color="success"
                          onClick={() => {
                            history.push(GET_STARTED_STEP_1_PATH, {
                              currentSubscription: subscription,
                              reactivate: true,
                            });
                          }}
                        >
                          <i className="fa fa-refresh" />
                          {t('subscriptions.reactivateSubscription')}
                        </Button>
                      )}
                    {/* Expired */}
                    {subscription.stripeSubscription?.status === 'canceled' && (
                      <h4 className="subscriptionExpiredText">{t('dashboard.subscriptionDetailsExpired')}</h4>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" xl="4" className="subscription">
                    <SubscriptionDetails subscription={subscription} />
                  </Col>
                  <Col lg="12" xl="4" className="subscription">
                    <h3>{t('subscriptions.activeWebsites')}</h3>
                    {subscription.websites
                      .filter((website: any) => website.active)
                      .reverse()
                      .map((website: any) => {
                        return (
                          <div key={website.id}>
                            <p>
                              <span
                                className={
                                  website.lastSKRequestDate ? 'statusIndicator connected' : 'statusIndicator connecting'
                                }
                              ></span>

                              <b>{website.title || website.domain}</b>
                            </p>
                            {website.title && (
                              <p style={{ marginLeft: '30px' }}>
                                <DomainLink domain={website.domain}></DomainLink>
                              </p>
                            )}
                          </div>
                        );
                      })}
                  </Col>
                  <Col lg="12" xl="4" className="subscription">
                    {(subscription.plan.type === PLAN_TYPE.AGENCY || subscription.plan.type === PLAN_TYPE.DESIGNER) && (
                      <>
                        <h3>{t('subscriptions.inactiveWebsites')}</h3>
                        {subscription.websites
                          .filter((website: any) => !website.active)
                          .map((website: any) => {
                            return (
                              <div key={website.id}>
                                <p>
                                  <span
                                    className={
                                      website.lastSKRequestDate
                                        ? 'statusIndicator connected'
                                        : 'statusIndicator connecting'
                                    }
                                  ></span>

                                  <b>{website.title || website.domain}</b>
                                </p>
                                {website.title && (
                                  <p style={{ marginLeft: '30px' }}>
                                    <DomainLink domain={website.domain}></DomainLink>
                                  </p>
                                )}
                              </div>
                            );
                          })}
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        })}
    </>
  );
};

export default Subscriptions;
