import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { IS_AUTHENTICATED } from 'graphql/queries/isAuthenticated';

export const PrivateRoute: React.FunctionComponent<RouteProps> = (props) => {
  const { data } = useQuery(IS_AUTHENTICATED);
  const location = useLocation();

  return data?.isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: location } }} />
  );
};

export const UnauthenticatedRoute: React.FunctionComponent<RouteProps> = (props) => {
  const { data } = useQuery(IS_AUTHENTICATED);
  const location = useLocation();

  return !location.pathname.includes('/oauth/connect') && data?.isAuthenticated ? (
    <Redirect to={{ pathname: '/', state: { from: location } }} />
  ) : (
    <Route {...props} />
  );
};

export const DASHBOARD_PATH = '/';

export const GET_STARTED_STEP_1_PATH = '/get-started/step-1';
export const GET_STARTED_STEP_2_PATH = '/get-started/step-2';
export const GET_STARTED_STEP_3_PATH = '/get-started/step-3';
