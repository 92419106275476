import React from 'react';

const DomainLink: React.FunctionComponent<any> = (props) => {
  if (!props.domain) return null;
  return (
    <a href={'https://' + props.domain} target="_blank" rel="noopener noreferrer">
      {props.domain}
      <i className="fa fa-external-link"></i>
    </a>
  );
};

export default DomainLink;
