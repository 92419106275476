import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Input, Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';

import { useParams, useHistory } from 'react-router-dom';

import { RESET_PASSWORD } from 'graphql/mutations/resetPassword';

const ResetPassword: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const { code } = useParams<{ code: string }>();

  const onSubmitHandler = async (values: any) => {
    toast.dismiss();

    const resetPasswordData = {
      password: values.password,
      passwordConfirmation: values.confirmPassword,
      code,
    };

    await resetPassword({ variables: resetPasswordData }).then(
      (success) => {
        history.replace('/login', { fromResetPassword: true });
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  return (
    <div className="register-page">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal">
              <img src={require('assets/img/sk-logo-black.png')} alt="SquareKicker" />
            </a>
          </div>
          <div className="auth-form">
            <h2 data-test="reset-password-title">{t('resetPassword.resetYourPassword')}</h2>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                password: '',
                confirmPassword: '',
                resetPasswordForm: '',
              }}
              validate={(values) => {
                const errors: any = {};

                if (!values.password) {
                  errors.password = '';
                  errors.resetPasswordForm = t('formValidation.forgotSomething');
                }

                if (!values.confirmPassword) {
                  errors.confirmPassword = '';
                  errors.resetPasswordForm = t('formValidation.forgotSomething');
                }

                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = t('formValidation.passwordsDontMatch');
                }

                return errors;
              }}
              onSubmit={onSubmitHandler}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <FormGroup className={errors.password !== undefined ? 'has-danger' : ''}>
                    <Label data-test="reset-password-newPassword-label" for="password">
                      {t('resetPassword.newPassword')}
                    </Label>
                    <Field data-test="reset-password-newPassword-input" type="password" name="password" as={Input} />
                    <ErrorMessage name="password" component={() => <p className="text-danger">{errors.password}</p>} />
                  </FormGroup>
                  <FormGroup className={errors.confirmPassword !== undefined ? 'has-danger' : ''}>
                    <Label data-test="reset-password-confirmPassword-label" for="confirmPassword">
                      {t('resetPassword.confirmPassword')}
                    </Label>
                    <Field
                      data-test="reset-password-confirmPassword-input"
                      type="password"
                      name="confirmPassword"
                      as={Input}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component={() => (
                        <p data-test="reset-password-error-message" className="text-danger">
                          {errors.confirmPassword}
                        </p>
                      )}
                    />
                  </FormGroup>
                  <ErrorMessage
                    name="resetPasswordForm"
                    component={() => (
                      <Alert data-test="reset-password-error-alert" color="danger">
                        {errors.resetPasswordForm}
                      </Alert>
                    )}
                  />
                  <Row>
                    <Col md="auto">
                      <Button
                        data-test="reset-password-button"
                        disabled={isSubmitting}
                        type="submit"
                        className="btn-round"
                        color="primary"
                      >
                        {t('resetPassword.resetPassword')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
