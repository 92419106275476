import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { useMutation } from '@apollo/client';

import { SQSP_OAUTH_ACTION } from 'consts';
import { generateCSRFState } from 'functions';
import { CREATE_SQSP_OAUTH_REQUEST } from 'graphql/mutations/sqspOAuthCreateRequest';

import { ReactComponent as SquarespaceLogo } from 'assets/img/sqsp-logo.svg';
import DomainLink from 'components/DomainLink';

const SQSPConnectWebsiteButton: React.FunctionComponent<{
  subscription: any;
  website: any;
}> = (props) => {
  const { t } = useTranslation();

  const [createSQSPOAuthRequest] = useMutation(CREATE_SQSP_OAUTH_REQUEST);

  const connectSQSPWebsite = async (subscriptionId: string, websiteId: string) => {
    const sqspOAuthCSRFState = generateCSRFState(28);
    localStorage.setItem('SQSPOAuthState', sqspOAuthCSRFState);
    localStorage.setItem('SQSPOAuthConnect', SQSP_OAUTH_ACTION.CONNECT_WEBSITE);
    localStorage.setItem('subscriptionId', subscriptionId);
    if (websiteId) localStorage.setItem('websiteId', websiteId);

    await createSQSPOAuthRequest({
      variables: {
        input: {
          data: {
            csrfToken: sqspOAuthCSRFState,
            redirectURI: process.env.REACT_APP_DASHBOARD_URL,
            action: SQSP_OAUTH_ACTION.CONNECT_WEBSITE,
          },
        },
      },
    });

    var params = new URLSearchParams({
      client_id: process.env.REACT_APP_SQSP_OAUTH_CLIENT_ID || '',
      redirect_uri: process.env.REACT_APP_SQSP_OAUTH_REDIRECT_URI || '',
      scope: 'website.products.read',
      state: sqspOAuthCSRFState,
      access_type: 'offline',
    });

    // Reconnect Same Website
    if (props.website?.sqspSiteId) params.append('website_id', props.website.sqspSiteId);

    const url = `${process.env.REACT_APP_SQSP_OAUTH_AUTHORIZE_URL || ''}?${params.toString()}`;
    window.location.assign(url);
  };

  return (
    <Row>
      <Col sm="12">
        {!props.website?.sqspConnected && (
          <div className="squarespace-btn squarespace-connect">
            <Button
              onClick={() => {
                connectSQSPWebsite(props.subscription.id, props.website?.id);
              }}
            >
              <SquarespaceLogo />
              {props.website?.sqspSiteId ? t('dashboard.reconnectWithSQSP') : t('dashboard.connectWithSQSP')}
            </Button>
          </div>
        )}
        {props.website?.sqspConnected && (
          <div className="text-center">
            <h3>{props.website.title || props.website.domain}</h3>
            {props.website.title && (
              <p>
                <DomainLink domain={props.website.domain}></DomainLink>
              </p>
            )}
            <div className="squarespace-btn squarespace-connect">
              <span className="squarespace-connected">
                <SquarespaceLogo />
                {t('dashboard.connectedWithSQSP')}
              </span>
            </div>
            <p>
              To manage your extensions, go to the{' '}
              <a
                href={`https://${props.website?.domain}/config/settings/extensions#connected-extensions`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Extensions Panel
              </a>{' '}
              in Squarespace.
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SQSPConnectWebsiteButton;
