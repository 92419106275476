import React from 'react';
import qs from 'qs';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { Link, useLocation } from 'react-router-dom';
import { Button, Input, Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Divider, Chip } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { SQSP_OAUTH_ACTION } from 'consts';
import { generateCSRFState, loginSuccessFunction } from 'functions';
import { LOGIN } from 'graphql/mutations/login';
import { CREATE_SQSP_OAUTH_REQUEST } from 'graphql/mutations/sqspOAuthCreateRequest';

import { ReactComponent as SquarespaceLogo } from 'assets/img/sqsp-logo.svg';

const Login: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const location: any = useLocation();

  const [login] = useMutation(LOGIN);

  const [createSQSPOAuthRequest] = useMutation(CREATE_SQSP_OAUTH_REQUEST);

  const loginHandler = async (values: any) => {
    toast.dismiss();

    const user = {
      identifier: values.email,
      password: values.password,
    };

    await login({ variables: { user } }).then(
      (success) => {
        loginSuccessFunction(success.data.login);
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  const squarespaceLoginHandler = async (values: any) => {
    toast.dismiss();

    const sqspOAuthCSRFState = generateCSRFState(28);
    localStorage.setItem('SQSPOAuthState', sqspOAuthCSRFState);
    localStorage.setItem('SQSPOAuthConnect', SQSP_OAUTH_ACTION.LOGIN);

    await createSQSPOAuthRequest({
      variables: {
        input: {
          data: {
            csrfToken: sqspOAuthCSRFState,
            redirectURI: process.env.REACT_APP_DASHBOARD_URL,
            action: SQSP_OAUTH_ACTION.LOGIN,
          },
        },
      },
    });

    var params = new URLSearchParams({
      client_id: process.env.REACT_APP_SQSP_OAUTH_CLIENT_ID || '',
      redirect_uri: process.env.REACT_APP_SQSP_OAUTH_REDIRECT_URI || '',
      scope: 'profile.read',
      state: sqspOAuthCSRFState,
      access_type: 'offline',
    });

    const url = `${process.env.REACT_APP_SQSP_OAUTH_AUTHORIZE_URL || ''}?${params.toString()}`;
    window.location.assign(url);
  };

  return (
    <div className="login-page">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal">
              <img src={require('assets/img/sk-logo-black.png')} data-test="skLogoImage" alt="SquareKicker" />
            </a>
          </div>
          <div className="page-title">
            <h2 data-test="loginPageTitle">{t('login.logIn')}</h2>
          </div>
          <div className="squarespace-btn squarespace-login">
            <Button display="flex" data-test="logInWithSquarespaceButton" onClick={squarespaceLoginHandler}>
              <SquarespaceLogo />
              {t('login.logInWithSquarespace')}
            </Button>
          </div>

          <div className="auth-divider">
            <Divider>
              <Chip label="OR" />
            </Divider>
          </div>
          <div className="auth-form">
            {location.state?.fromResetPassword && (
              <Alert color="success">
                <p data-test="reset-password-success-message">{t('login.passwordResetSuccess')}</p>
              </Alert>
            )}
            {qs.parse(location.search, { ignoreQueryPrefix: true })?.changePasswordSuccess !== undefined && (
              <Alert color="success">
                <p data-test="change-password-success-message">{t('login.changePasswordSuccess')}</p>
              </Alert>
            )}
            {qs.parse(location.search, { ignoreQueryPrefix: true })?.reauthenticate !== undefined && (
              <Alert color="danger">
                <p>{t('login.reauthenticate')}</p>
              </Alert>
            )}
            {qs.parse(location.search, { ignoreQueryPrefix: true })?.sessionExpired !== undefined && (
              <Alert color="danger">
                <p>{t('login.sessionExpired')}</p>
              </Alert>
            )}
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                email: '',
                password: '',
                loginForm: '',
              }}
              validate={(values) => {
                const errors: any = {};
                if (!values.email) {
                  errors.email = '';
                  errors.loginForm = t('formValidation.forgotSomething');
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = t('formValidation.invalidEmail');
                }

                if (!values.password) {
                  errors.password = '';
                  errors.loginForm = t('formValidation.forgotSomething');
                }

                return errors;
              }}
              onSubmit={loginHandler}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <FormGroup className={errors.email !== undefined ? 'has-danger' : ''}>
                    <Label for="email" data-test="emailLabel">
                      {t('login.email')}
                    </Label>
                    <Field type="email" name="email" autoComplete="email" as={Input} noValidate />
                    <ErrorMessage name="email" component={() => <p className="text-danger">{errors.email}</p>} />
                  </FormGroup>
                  <FormGroup className={errors.password !== undefined ? 'has-danger' : ''}>
                    <Label for="password" data-test="passwordLabel">
                      {t('login.password')}
                    </Label>
                    <Field type="password" name="password" autoComplete="current-password" as={Input} />
                    <ErrorMessage name="password" component={() => <p className="text-danger">{errors.password}</p>} />
                    <span className="forgot-link" data-test="forgotPasswordLink">
                      <Link to="/forgot">{t('login.forgotPassword')} </Link>
                    </span>
                  </FormGroup>

                  <ErrorMessage
                    name="loginForm"
                    component={() => (
                      <Alert color="danger" data-test="loginFormErrorMessage">
                        {errors.loginForm}
                      </Alert>
                    )}
                  />
                  <Row>
                    <Col md="auto">
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn-round btn-max-size"
                        color="primary"
                        data-test="loginButton"
                      >
                        {t('login.logIn')}
                      </Button>
                    </Col>
                    <Col md="auto" className="signup-link" data-test="signUpLink">
                      <span>
                        <Trans i18nKey="login.signUp">
                          Not a member? <Link to="/signup">Sign Up</Link>
                        </Trans>
                      </span>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
