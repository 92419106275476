import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Row, Col } from 'reactstrap';

import { WEBSITE } from 'graphql/queries/website';
import { UPDATE_WEBSITE } from 'graphql/mutations/updateWebsite';
import { graphQLErrorHandler } from 'graphql/apollo';
import DomainLink from 'components/DomainLink';

const ConnectExistingWebsiteModal: React.FunctionComponent<{
  toggle: any;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const { existingSubscriptionId, existingWebsiteId } = useParams<{
    subscriptionId: string;
    websiteId: string;
    existingSubscriptionId: string;
    existingWebsiteId: string;
  }>();

  const { loading, data: existingWebsiteData } = useQuery(WEBSITE, {
    variables: { id: existingWebsiteId },
  });

  const [updateWebsite] = useMutation(UPDATE_WEBSITE);

  const updateWebsiteHandler = (subscriptionId: any, websiteId: any, active: boolean) => {
    updateWebsite({
      variables: {
        website: {
          where: {
            id: websiteId,
          },
          data: {
            active,
          },
        },
      },
    }).then(
      (success) => {
        if (success.data.updateWebsite.website.lastSKRequestDate) {
          history.push('/');
        } else {
          history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
        }
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  if (loading || !existingWebsiteData) return null;

  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );

  return (
    <Modal className="website-settings-modal" size="lg" isOpen={true} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {t('dashboard.existingWebsiteModalTitle')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <div className="text-center">
              <h3>{existingWebsiteData.website.title || existingWebsiteData.website.domain}</h3>
              {existingWebsiteData.website.title && (
                <p>
                  <DomainLink domain={existingWebsiteData.website.domain}></DomainLink>
                </p>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p className="text-center font-weight-bold">
                {existingWebsiteData.website.active &&
                  existingWebsiteData.website.lastSKRequestDate === null &&
                  t('dashboard.connectExistingWebsiteModalMessageInstall')}
                {existingWebsiteData.website.active &&
                  existingWebsiteData.website.lastSKRequestDate &&
                  t('dashboard.connectExistingWebsiteModalMessageWebsiteSettings')}
                {existingWebsiteData.website.active === false &&
                  t('dashboard.connectExistingWebsiteModalMessageRestore')}
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {existingWebsiteData.website.active && existingWebsiteData.website.lastSKRequestDate === null && (
                <Button
                  className="btn-round no-hide"
                  color="charcoal"
                  onClick={() =>
                    history.push(`/subscription/${existingSubscriptionId}/website/${existingWebsiteId}/settings`)
                  }
                >
                  {t('dashboard.connectExistingWebsiteModalButtonInstall')}
                </Button>
              )}
              {existingWebsiteData.website.active && existingWebsiteData.website.lastSKRequestDate && (
                <Button
                  className="btn-round no-hide"
                  color="charcoal"
                  onClick={() =>
                    history.push(`/subscription/${existingSubscriptionId}/website/${existingWebsiteId}/settings`)
                  }
                >
                  {t('dashboard.connectExistingWebsiteModalButtonWebsiteSettings')}
                </Button>
              )}
              {existingWebsiteData.website.active === false && (
                <Button
                  className="btn-round no-hide"
                  color="charcoal"
                  onClick={() => updateWebsiteHandler(existingSubscriptionId, existingWebsiteId, true)}
                >
                  {t('dashboard.connectExistingWebsiteModalButtonRestore')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.closeButton')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectExistingWebsiteModal;
