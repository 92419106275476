import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const REGISTER = gql`
  ${ME_FIELDS}
  mutation Register($user: UsersPermissionsRegisterInput!) {
    register(input: $user) {
      jwt
      user {
        ...MeFields
      }
    }
  }
`;
