import { Integration } from '@sentry/types';

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration, Offline as OfflineIntegration } from '@sentry/integrations';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import './i18n';
import './stripe';
import { client } from 'graphql/apollo';
import { PrivateRoute, UnauthenticatedRoute } from 'routes';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/css/sk-20-styling.css';

import AuthLayout from 'layouts/AuthLayout';
import DashboardLayout from 'layouts/DashboardLayout';
import FullscreenLayout from 'layouts/FullscreenLayout';
import Logout from 'views/Logout';

const history: any = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }) as Integration,
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }) as Integration,
      new OfflineIntegration() as Integration,
    ],
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    initialScope: {},
  });
}

const sentryErrorFallback = () => (
  <div id="error-fallback">
    <iframe
      title="SquareKicker"
      width="100%"
      height="100%"
      frameBorder="0"
      src="https://parrot.squarekicker.com"
    ></iframe>
  </div>
);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={sentryErrorFallback} showDialog>
    <ApolloProvider client={client}>
      <ToastContainer theme="colored" />
      <Router history={history}>
        <Switch>
          <Route path="/logout" component={Logout}></Route>
          <UnauthenticatedRoute
            exact={true}
            path={[
              '/login',
              '/signup',
              '/signup-with-email',
              '/signup-with-squarespace',
              '/forgot',
              '/reset/:code',
              '/oauth/connect/login',
              '/oauth/connect/signup',
              '/oauth/connect/account',
              '/oauth/connect/website',
            ]}
            component={AuthLayout}
          ></UnauthenticatedRoute>
          <PrivateRoute path="/get-started" component={FullscreenLayout}></PrivateRoute>
          <PrivateRoute path="/" component={DashboardLayout}></PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
