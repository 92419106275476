import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import DomainLink from 'components/DomainLink';

const RemoveWebsiteConfirmationModal: React.FunctionComponent<{
  toggle: any;
  state: any;
  confirmationFunction: any;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={true}
      toggle={props.toggle}
      header={t('dashboard.confirmRemoveWebsite')}
      body={
        <>
          <Row>
            <Col sm="12">
              <span style={{ textAlign: 'center' }}>
                <h3>{props.state.website.title || props.state.website.domain}</h3>
                {props.state.website.title && (
                  <p>
                    <DomainLink domain={props.state.website.domain}></DomainLink>
                  </p>
                )}
                <p>
                  <strong>{t('dashboard.confirmRemoveWebsiteText1')}</strong>
                </p>
              </span>
              <p>{t('dashboard.confirmRemoveWebsiteText2')}</p>
            </Col>
          </Row>
        </>
      }
      footer={
        <>
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.cancelButton')}
          </Button>
          <Button
            className="btn-round no-hide"
            color="danger"
            onClick={() => {
              props.confirmationFunction(props.state.website.id);
              props.toggle();
            }}
          >
            {t('dashboard.confirmRemoveWebsiteModalRemoveButton')}
          </Button>
        </>
      }
    />
  );
};

export default RemoveWebsiteConfirmationModal;
