import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

const OAuthConnecting = () => {
  const { t } = useTranslation();

  return (
    <div className="login-page">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal">
              <img src={require('assets/img/sk-logo-black.png')} alt="SquareKicker" />
            </a>
          </div>
          <div className="auth-form d-flex flex-column justify-content-center" style={{ padding: '20px' }}>
            <h2 className="loading-ellipsis" style={{ textAlign: 'center' }}>
              {t('dashboard.websiteStatusConnecting')}
            </h2>
            <iframe
              className="parrotFrame"
              src="https://parrot.squarekicker.com"
              title="Parrots are cool."
              style={{ border: 'none' }}
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OAuthConnecting;
