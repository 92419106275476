import { gql } from '@apollo/client';

export const PLAN_FIELDS = gql`
  fragment PlanFields on Plan {
    id
    name
    description
    type
    annualPrice
    monthlyPrice
    maxDomains
    maxUsers
    imageURL
  }
`;
