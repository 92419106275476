import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const UPDATE_PAYMENT_METHOD = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  mutation UpdatePaymentMethod($subscriptionId: String!, $paymentMethod: JSON, $billingDetails: JSON) {
    updatePaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethod: $paymentMethod
      billingDetails: $billingDetails
    ) {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
