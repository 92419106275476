import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const InstallationSuccess: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm="12">
        <h5 className="text-success installation-step-icon">
          <i className="fa fa-check"></i>
        </h5>
        <h5 className="text-success installation-step-title">
          {t('dashboard.websiteSettingsModalInstallationSuccessTitle')}
        </h5>
        <div className="installation-steps">
          <p>{t('dashboard.websiteSettingsModalInstallationSuccessText')}</p>
        </div>
      </Col>
    </Row>
  );
};

export default InstallationSuccess;
