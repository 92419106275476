import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const SUBSCRIPTIONS = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  query Subscriptions {
    subscriptions {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
