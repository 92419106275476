import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { stripePromise } from 'stripe';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Row, Col, FormGroup, Label, Alert, Input, Collapse, Button } from 'reactstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Select from 'components/Select';

import { countryCodes } from 'consts';
import { ME } from 'graphql/queries/me';

import { UPDATE_PAYMENT_METHOD } from 'graphql/mutations/updatePaymentMethod';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#262626',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#d05a3c',
    },
  },
};

const StripeForm: React.FunctionComponent<{
  subscription: any;
  submitTrigger: { value: boolean };
  modalToggle: any;
}> = (props) => {
  const { t } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();
  const formRef = useRef<any>();

  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);

  const { data: meData, loading } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (props.submitTrigger.value && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [props.submitTrigger.value]);

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCVCComplete, setCardCVCComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [existingPaymentMethod, setExistingPaymentMethod] = useState<any>(null);

  const [changeCard, setChangeCard] = useState(false);
  const toggleChangeCard = () => {
    if (changeCard) setExistingPaymentMethod(props.subscription.stripePaymentMethod);
    setChangeCard(!changeCard);
  };

  const [newCard, setNewCard] = useState(false);
  const toggleNewCard = () => {
    setExistingPaymentMethod(null);
    setNewCard(!newCard);
  };

  if (loading || !meData || !stripe || !elements) return null;

  if (existingPaymentMethod === null && !newCard) {
    setExistingPaymentMethod(props.subscription.stripePaymentMethod);
  }

  // prettier-ignore
  const cardBrand: any = {
      visa: <img src={require('assets/img/payment-method-icons/visa.svg')} alt="Visa" />,
      mastercard: <img src={require('assets/img/payment-method-icons/mastercard.svg')} alt="Mastercard" />,
      amex: <img src={require('assets/img/payment-method-icons/amex.svg')} alt="Amex" />,
      jcb: <img src={require('assets/img/payment-method-icons/jcb.svg')} alt="JCB" />,
      generic: <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />,
    };

  const initialValues = {
    fullName: existingPaymentMethod?.billing_details.name || '',
    address: existingPaymentMethod?.billing_details.address.line1 || '',
    city: existingPaymentMethod?.billing_details.address.city || '',
    postalCode: existingPaymentMethod?.billing_details.address.postal_code || '',
    country: existingPaymentMethod?.billing_details.address.country || '',
    cardNumber: '',
    expiry: '',
    cvc: '',
    paymentForm: '',
  };

  const onSubmitHandler = async (values: any, { resetForm, setSubmitting }: any) => {
    toast.dismiss();
    setErrorMessage(null);

    const billingDetails = {
      name: values.fullName,
      email: meData.me.email,
      address: {
        line1: values.address,
        city: values.city,
        postal_code: values.postalCode,
        country: values.country,
      },
    };

    let paymentMethodData: any = null;

    if (newCard) {
      const cardElement: any = elements.getElement(CardNumberElement);
      paymentMethodData = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });
      if (paymentMethodData?.paymentMethod) paymentMethodData.paymentMethod.newCard = true;
    } else if (changeCard) {
      paymentMethodData = { paymentMethod: existingPaymentMethod };
    }

    if (paymentMethodData?.error) {
      setErrorMessage(paymentMethodData.error.message);
      resetForm({ values });
      return;
    } else {
      // Update Payment Method
      await updatePaymentMethod({
        variables: {
          subscriptionId: props.subscription.id,
          paymentMethod: paymentMethodData?.paymentMethod,
          billingDetails,
        },
      }).then(
        async (success) => {
          props.modalToggle();
        },
        (error) => {
          if (error?.graphQLErrors[0]) {
            setErrorMessage(error?.graphQLErrors[0].message);
          } else {
            graphQLErrorHandler(error);
          }
          resetForm({ values });
        },
      );
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      enableReinitialize
      validate={(values) => {
        const errors: any = {};

        if (!values.fullName) {
          errors.fullName = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.address) {
          errors.address = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.city) {
          errors.city = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.postalCode) {
          errors.postalCode = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }
        if (!values.country) {
          errors.country = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (newCard && !cardNumberComplete) {
          errors.cardNumber = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (newCard && !cardExpiryComplete) {
          errors.expiry = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        if (newCard && !cardCVCComplete) {
          errors.cvc = '';
          errors.paymentForm = t('formValidation.forgotSomething');
        }

        return errors;
      }}
      onSubmit={onSubmitHandler}
      innerRef={formRef}
    >
      {({ dirty, submitCount, errors, isSubmitting }) => (
        <Form>
          <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
            <div className="stripe-payment-form">
              <Row>
                <Col sm="12">
                  <div>
                    <h3>{t('dashboard.subscriptionDetailsUpdatePaymentMethodSavedCard')}</h3>

                    <Collapse isOpen={!changeCard}>
                      <div className="float-left">
                        <p>
                          {cardBrand[props.subscription.stripePaymentMethod.card.brand] || (
                            <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />
                          )}{' '}
                          •••• {props.subscription.stripePaymentMethod.card.last4}
                        </p>
                        <p>
                          {t('newSubscription.paymentFormExpiry')}{' '}
                          {props.subscription.stripePaymentMethod.card.exp_month.toString().padStart(2, '0')}/
                          {props.subscription.stripePaymentMethod.card.exp_year}
                        </p>
                        <p className="cardholder-name">{props.subscription.stripePaymentMethod.billing_details.name}</p>
                      </div>
                    </Collapse>

                    <Collapse isOpen={changeCard}>
                      {meData.me.stripePaymentMethods?.length > 0 && (
                        <>
                          <Collapse isOpen={!newCard}>
                            <p>
                              <b>{t('newSubscription.paymentFormUseExistingCard')}</b>
                            </p>

                            {meData.me.stripePaymentMethods.map((stripePaymentMethod: any) => {
                              return (
                                <div
                                  className={
                                    existingPaymentMethod?.id === stripePaymentMethod.id
                                      ? 'payment-method selected'
                                      : 'payment-method'
                                  }
                                  key={stripePaymentMethod.id}
                                  onClick={() => setExistingPaymentMethod(stripePaymentMethod)}
                                >
                                  <p>
                                    {cardBrand[stripePaymentMethod.card.brand] || (
                                      <img src={require('assets/img/payment-method-icons/generic.svg')} alt="Generic" />
                                    )}{' '}
                                    •••• {stripePaymentMethod.card.last4}
                                  </p>
                                  <p>
                                    {t('newSubscription.paymentFormExpiry')}{' '}
                                    {stripePaymentMethod.card.exp_month.toString().padStart(2, '0')}/
                                    {stripePaymentMethod.card.exp_year}
                                  </p>
                                  <p className="cardholder-name">{stripePaymentMethod.billing_details.name}</p>
                                </div>
                              );
                            })}
                          </Collapse>
                        </>
                      )}

                      <Collapse isOpen={newCard}>
                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="cardNumber">{t('newSubscription.paymentFormCardNumber')}</Label>
                          <CardNumberElement
                            id="cardNumber"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardNumberComplete(e.complete);
                            }}
                          />
                          <ErrorMessage
                            name="cardNumber"
                            component={() => <p className="text-danger">{errors.cardNumber}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="expiry">{t('newSubscription.paymentFormCardExpiry')}</Label>
                          <CardExpiryElement
                            id="expiry"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardExpiryComplete(e.complete);
                            }}
                          />
                          <ErrorMessage
                            name="expiry"
                            component={() => <p className="text-danger">{errors.expiry}</p>}
                          />
                        </FormGroup>

                        <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                          <Label for="cvc">{t('newSubscription.paymentFormCardCVC')}</Label>
                          <CardCvcElement
                            id="cvc"
                            options={ELEMENT_OPTIONS}
                            onChange={(e: any) => {
                              setCardCVCComplete(e.complete);
                            }}
                          />
                          <ErrorMessage name="cvc" component={() => <p className="text-danger">{errors.cvc}</p>} />
                        </FormGroup>
                      </Collapse>
                    </Collapse>

                    <div className="float-right">
                      {changeCard && (
                        <Button className="btn-round" color={newCard ? 'ocean' : 'success'} onClick={toggleNewCard}>
                          {newCard ? t('common.cancelButton') : t('dashboard.subscriptionDetailsPaymentMethodNewCard')}
                        </Button>
                      )}

                      {!newCard && (
                        <Button
                          className="btn-round"
                          color={changeCard ? 'ocean' : 'success'}
                          onClick={toggleChangeCard}
                        >
                          {changeCard
                            ? t('common.cancelButton')
                            : t('dashboard.subscriptionDetailsPaymentMethodChangeCard')}
                        </Button>
                      )}
                    </div>
                  </div>

                  <div style={{ clear: 'both', paddingTop: '20px' }}>
                    <h3>{t('dashboard.subscriptionDetailsUpdatePaymentMethodBillingDetails')}</h3>
                    <FormGroup className={errors.fullName !== undefined ? 'has-danger' : ''}>
                      <Label for="fullName">{t('newSubscription.paymentFormFullName')}</Label>
                      <Field type="text" name="fullName" as={Input} />
                      <ErrorMessage
                        name="fullName"
                        component={() => <p className="text-danger">{errors.fullName}</p>}
                      />
                    </FormGroup>

                    <FormGroup className={errors.address !== undefined ? 'has-danger' : ''}>
                      <Label for="address">{t('newSubscription.paymentFormAddress')}</Label>
                      <Field name="address" as={Input} />
                      <ErrorMessage name="address" component={() => <p className="text-danger">{errors.address}</p>} />
                    </FormGroup>

                    <Row style={{ padding: 0, border: 'none' }}>
                      <Col md="6" style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}>
                        <FormGroup className={errors.city !== undefined ? 'has-danger' : ''}>
                          <Label for="city">{t('newSubscription.paymentFormCity')}</Label>
                          <Field name="city" as={Input} />
                          <ErrorMessage name="city" component={() => <p className="text-danger">{errors.city}</p>} />
                        </FormGroup>
                      </Col>
                      <Col md="6" style={{ padding: 0 }}>
                        <FormGroup className={errors.postalCode !== undefined ? 'has-danger' : ''}>
                          <Label for="postalCode">{t('newSubscription.paymentFormPostalCode')}</Label>
                          <Field name="postalCode" as={Input} />
                          <ErrorMessage
                            name="postalCode"
                            component={() => <p className="text-danger">{errors.postalCode}</p>}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup className={errors.country !== undefined ? 'has-danger' : ''}>
                      <Label for="country">{t('newSubscription.paymentFormCountry')}</Label>
                      <Select name="country">
                        <option value={undefined} hidden></option>
                        {Object.keys(countryCodes).map((countryCode: any) => (
                          <option key={countryCode} value={countryCode}>
                            {countryCodes[countryCode]}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage name="country" component={() => <p className="text-danger">{errors.country}</p>} />
                    </FormGroup>

                    <ErrorMessage
                      name="paymentForm"
                      component={() => <Alert color="danger">{errors.paymentForm}</Alert>}
                    />

                    {errorMessage && (
                      <Alert color="danger">
                        <p>
                          <b>{t('dashboard.subscriptionDetailsUpdatePaymentMethodFailed')}</b>
                        </p>
                        <p>{errorMessage}</p>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const UpdatePaymentMethodForm: React.FunctionComponent<{
  subscription: any;
  submitTrigger: { value: boolean };
  modalToggle: any;
}> = (props) => {
  stripePromise.catch(() => {
    toast.dismiss();
    graphQLErrorHandler(null);
  });

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        subscription={props.subscription}
        submitTrigger={props.submitTrigger}
        modalToggle={props.modalToggle}
      />
    </Elements>
  );
};

export default UpdatePaymentMethodForm;
