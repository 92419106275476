import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, Navbar, Nav, Dropdown } from 'reactstrap';

import { NavLink } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { ME } from 'graphql/queries/me';

const DashboardNavbar: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(ME);

  const [state, setState] = useState({ collapseOpen: false, dropdownOpen: false });

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    let newState = {
      collapseOpen: !state.collapseOpen,
      dropdownOpen: state.dropdownOpen,
    };
    setState(newState);
  };

  const toggleDropdown = () => {
    let newState = {
      collapseOpen: state.collapseOpen,
      dropdownOpen: !state.dropdownOpen,
    };
    setState(newState);
  };

  if (loading) return null;

  return (
    <>
      <Navbar className="navbar-absolute fixed-top navbar-transparent" expand="lg">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={state.collapseOpen}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          // data-target="#navigation"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse className="justify-content-end" navbar isOpen={state.collapseOpen}>
          <Nav navbar>
            <Dropdown nav isOpen={state.dropdownOpen || state.collapseOpen} toggle={toggleDropdown}>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                nav
                style={{ display: state.collapseOpen ? 'none' : 'inherit' }}
              >
                <h5 data-test="navbar-username">
                  {data?.me.firstName} {data?.me.lastName} <i className="fa fa-chevron-down" />
                </h5>
              </DropdownToggle>
              <DropdownMenu aria-labelledby="navbarDropdownMenuLink" end>
                <DropdownItem>
                  <NavLink to="/account" className="nav-link" data-test="navbar-my-account">
                    {t('dashboardNavbar.myAccount')}
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/logout" className="nav-link" data-test="navbar-logout">
                    {t('dashboardNavbar.logout')}
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default DashboardNavbar;
