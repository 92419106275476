import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import DomainLink from 'components/DomainLink';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { WEBSITE } from 'graphql/queries/website';

import { SUBSCRIPTION } from 'graphql/queries/subscription';
import { TRANSFER_WEBSITE } from 'graphql/mutations/transferWebsite';
import { graphQLErrorHandler } from 'graphql/apollo';
import { PLAN_TYPE } from 'consts';

const TransferWebsiteConfirmationModal: React.FunctionComponent<{
  toggle: any;
}> = (props) => {
  const { t } = useTranslation();

  const [transferWebsite] = useMutation(TRANSFER_WEBSITE);

  const { websiteId, fromSubscriptionId, toSubscriptionId } = useParams<{
    websiteId: string;
    fromSubscriptionId: string;
    toSubscriptionId: string;
  }>();

  const { data, loading } = useQuery(WEBSITE, {
    variables: { id: websiteId },
  });

  const { data: fromSubscriptionData, loading: fromSubscriptionLoading } = useQuery(SUBSCRIPTION, {
    variables: { id: fromSubscriptionId },
  });

  const { data: toSubscriptionData, loading: toSubscriptionLoading } = useQuery(SUBSCRIPTION, {
    variables: { id: toSubscriptionId },
  });

  const transferWebsiteHandler = async (
    websiteId: string,
    transferFromSubscriptionId: string,
    transferToSubscriptionId: string,
  ) => {
    await transferWebsite({
      variables: {
        websiteId,
        transferFromSubscriptionId,
        transferToSubscriptionId,
      },
    }).then(
      (success) => {
        props.toggle();
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  if (
    loading ||
    fromSubscriptionLoading ||
    toSubscriptionLoading ||
    !data ||
    !fromSubscriptionData ||
    !toSubscriptionData
  )
    return null;

  return (
    <Modal
      isOpen={true}
      toggle={props.toggle}
      header={t('dashboard.confirmTransferWebsite')}
      body={
        <>
          <Row>
            <Col sm="12">
              <span style={{ textAlign: 'center' }}>
                <h3>{data.website.title || data.website.domain}</h3>
                {data.website.title && (
                  <p>
                    <DomainLink domain={data.website.domain}></DomainLink>
                  </p>
                )}
                <p>
                  <strong>{t('dashboard.confirmTransferWebsiteText1')}</strong>
                </p>
                <p>
                  {fromSubscriptionData.subscription.plan.type === PLAN_TYPE.DESIGNER ||
                  fromSubscriptionData.subscription.plan.type === PLAN_TYPE.AGENCY
                    ? t('dashboard.confirmTransferWebsiteText2', {
                        fromSubscription: fromSubscriptionData.subscription.plan.name,
                        toSubscription: toSubscriptionData.subscription.plan.name,
                      })
                    : t('dashboard.confirmTransferWebsiteText2Cancel', {
                        fromSubscription: fromSubscriptionData.subscription.plan.name,
                        toSubscription: toSubscriptionData.subscription.plan.name,
                      })}
                </p>
              </span>
            </Col>
          </Row>
        </>
      }
      footer={
        <>
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.cancelButton')}
          </Button>
          <Button
            className="btn-round no-hide"
            color="danger"
            onClick={() => {
              transferWebsiteHandler(data.website.id, fromSubscriptionId, toSubscriptionId);
            }}
          >
            {t('dashboard.confirmTransferWebsiteModalTransferButton')}
          </Button>
        </>
      }
    />
  );
};

export default TransferWebsiteConfirmationModal;
