import React from 'react';
import { Navbar, Nav, Container } from 'reactstrap';
import { Link, Route, useLocation } from 'react-router-dom';

import { DASHBOARD_PATH, GET_STARTED_STEP_1_PATH, GET_STARTED_STEP_2_PATH } from 'routes';

const FullscreenNavbar: React.FunctionComponent = () => {
  const location = useLocation();

  return (
    <>
      <Navbar className="navbar-absolute fixed-top navbar-transparent" expand="lg">
        <Container fluid className="d-flex justify-content-end">
          <Nav navbar>
            <Route exact path={[GET_STARTED_STEP_1_PATH, GET_STARTED_STEP_2_PATH]}>
              <Link to={DASHBOARD_PATH} className="nav-link">
                <h5>
                  <i
                    className="fa fa-times"
                    style={location.pathname === '/get-started/step-1' ? { color: '#fff' } : { color: 'inherit' }}
                  />
                </h5>
              </Link>
            </Route>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default FullscreenNavbar;
