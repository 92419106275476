import React from 'react';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import ApolloLinkTimeout from 'apollo-link-timeout';
import { IS_AUTHENTICATED } from './queries/isAuthenticated';

const cache = new InMemoryCache();

cache.writeQuery({
  query: IS_AUTHENTICATED,
  data: {
    isAuthenticated: !!localStorage.getItem('token'),
  },
});

const timeoutLink = new ApolloLinkTimeout(10000);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((request: any, previousContext: any) => {
  const { headers } = previousContext;
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // logout if no auth token and making authenticated request
  if (
    !token &&
    ![
      'Login',
      'Register',
      'ForgotPassword',
      'ResetPassword',
      'ConnectSQSPAccount',
      'SQSPOAuthLogin',
      'SQSPOAuthRegister',
      'CreateSQSPOAuthRequest',
    ].includes(request.operationName)
  ) {
    client.clearStore();
    cache.writeQuery({
      query: IS_AUTHENTICATED,
      data: { isAuthenticated: false },
    });
    localStorage.removeItem('token');
    window.location.replace('/login?reauthenticate');
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const supportAccessLink = setContext((request: any, previousContext: any) => {
  const { headers } = previousContext;
  // get the supportAccessUserId token from local storage if it exists
  const supportAccessUserId = localStorage.getItem('supportAccessUserId');
  // return the headers to the context so httpLink can read them
  if (supportAccessUserId) {
    return {
      headers: {
        ...headers,
        'SK-Support-Access-User-ID': supportAccessUserId,
      },
    };
  } else {
    return { headers };
  }
});

/*
As per https://github.com/apollographql/apollo-client/issues/5708 and
https://github.com/apollographql/apollo-feature-requests/issues/220
error handling here will still throw an error on mutations, unless onError function is passed.
Until that is fixed use `const [cb, {data, error}] = useMutation(MUTATION, {onError: () => null})` as work around.
*/
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      console.log(graphQLError);
      const { message, locations, path, extensions } = graphQLError;
      console.error(`[GraphQL error]:
  Message: ${JSON.stringify(message)},
  Location: ${JSON.stringify(locations)},
  Path: ${JSON.stringify(path)}
  Extensions: ${JSON.stringify(extensions)}`);
    });

    if (graphQLErrors[0].message === 'Invalid token.') {
      client.clearStore();
      cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: { isAuthenticated: false },
      });
      localStorage.removeItem('token');
      window.location.replace('/login?sessionExpired');
    } else if (graphQLErrors[0].message === 'Forbidden') {
      toast.error(
        <div className="notification-alert">
          <h5 data-test="graphql-error-title">{i18next.t('common.somethingWentWrongTitle')}</h5>
          <p data-test="graphql-error-description">
            {graphQLErrors[0]?.message || i18next.t('common.somethingWentWrongText')}
          </p>
        </div>,
        {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          toastId: 'graphql-error',
        },
      );
    }
  } else if (networkError) {
    console.error(`[Network error]: ${JSON.stringify(networkError.message)}`);
    toast.error(
      <div className="notification-alert">
        <h5 data-test="graphql-error-title">{i18next.t('common.networkErrorTitle')}</h5>
        <p data-test="graphql-error-description">{i18next.t('common.networkErrorText')}</p>
      </div>,
      {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        toastId: 'network-error',
      },
    );
  }
});

export const client = new ApolloClient({
  cache,
  link: errorLink.concat(supportAccessLink.concat(authLink.concat(timeoutLink.concat(httpLink)))),
  resolvers: {},
});

export const graphQLErrorHandler = (error: any) => {
  if (error?.graphQLErrors?.[0]?.extensions?.exception?.data?.data?.[0]?.messages?.[0]?.message) {
    toast.error(
      <div className="notification-alert">
        <h5 data-test="graphql-error-title">{i18next.t('common.somethingWentWrongTitle')}</h5>
        <ul>
          {error.graphQLErrors.map(({ extensions }: any, i: number) => (
            <li
              data-test="graphql-error-description"
              key={i}
              dangerouslySetInnerHTML={{
                __html: extensions?.exception?.data?.data[0].messages[0].message,
              }}
            ></li>
          ))}
        </ul>
      </div>,
      {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        toastId: 'graphql-error',
      },
    );
  } else {
    toast.error(
      <div className="notification-alert">
        <h5 data-test="graphql-error-title">{error?.title || i18next.t('common.somethingWentWrongTitle')}</h5>
        <p
          data-test="graphql-error-description"
          dangerouslySetInnerHTML={{
            __html: error?.message || i18next.t('common.somethingWentWrongText'),
          }}
        ></p>
      </div>,
      {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        toastId: 'graphql-error',
      },
    );
  }
};
