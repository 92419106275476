import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Button, Row } from 'reactstrap';

const FreeTrialBanner: React.FunctionComponent<{
  plan: any;
  nextStep: any;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Row className="free-trial-banner" data-test="free-trial-banner">
      <Col lg="4">
        <h1>{t('dashboard.getStartedButton')}</h1>
      </Col>
      <Col lg="8">
        <h2>{t('newSubscription.step1FreeTrialBannerText')}</h2>
        <h4>{t('newSubscription.step1FreeTrialBannerNoCreditCard')}</h4>
        <p>
          <Button
            className="btn-round no-hide btn-wide"
            data-test="start-free-trial-button"
            color={'plan-color-' + props.plan.type}
            size="lg"
            onClick={() => {
              props.nextStep(props.plan);
            }}
          >
            {t('newSubscription.step1StartTrial')}
          </Button>
        </p>
      </Col>
    </Row>
  );
};

export default FreeTrialBanner;
