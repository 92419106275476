import { useMutation, useQuery } from '@apollo/client';
import { PLAN_TYPE } from 'consts';
import { graphQLErrorHandler } from 'graphql/apollo';
import { CREATE_FREE_TRIAL } from 'graphql/mutations/createFreeTrial';
import { PLANS } from 'graphql/queries/plans';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Col, Row } from 'reactstrap';
import { GET_STARTED_STEP_1_PATH, GET_STARTED_STEP_2_PATH, GET_STARTED_STEP_3_PATH } from 'routes';
import FreeTrialBanner from './components/FreeTrialBanner';

import Step1 from './components/Steps/Step1';
import Step2 from './components/Steps/Step2';
import Step3 from './components/Steps/Step3';

const NewSubscription: React.FunctionComponent = (props) => {
  const history: any = useHistory();

  const { data, loading } = useQuery(PLANS);

  const [createFreeTrial] = useMutation(CREATE_FREE_TRIAL);

  const currentSubscription = history?.location?.state?.currentSubscription;

  const startFreeTrial = (selectedPlan: any) => {
    // Free Trial Subscription
    toast.dismiss();
    createFreeTrial().then(
      (success) => {
        // @ts-ignore
        tap('trial', success.data.createFreeTrial.user.id);
        history.replace(GET_STARTED_STEP_3_PATH, {
          plan: selectedPlan,
          website: success.data.createFreeTrial.websites[0],
          subscription: success.data.createFreeTrial,
        });
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  if (loading || !data) return null;

  return (
    <>
      <Route exact path={GET_STARTED_STEP_1_PATH}>
        {!currentSubscription && (
          <FreeTrialBanner
            plan={data.plans.find((plan: any) => plan.type === PLAN_TYPE.FREE_TRIAL)}
            nextStep={() => startFreeTrial(data.plans[0])}
          />
        )}
      </Route>

      <div className="content get-started">
        <Row>
          <Col md="12">
            <Switch>
              <Route path={GET_STARTED_STEP_1_PATH} component={Step1} />
              <Route path={GET_STARTED_STEP_2_PATH} component={Step2} />
              <Route path={GET_STARTED_STEP_3_PATH} component={Step3} />
            </Switch>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewSubscription;
