import { client } from 'graphql/apollo';
import * as Sentry from '@sentry/browser';
import moment from 'moment';

import { IS_AUTHENTICATED } from 'graphql/queries/isAuthenticated';

export const loginSuccessFunction = (loginData: any) => {
  client.writeQuery({ query: IS_AUTHENTICATED, data: { isAuthenticated: true } });
  localStorage.setItem('token', loginData.jwt);
  const { id, email, firstName, lastName } = loginData.user;
  Sentry.setUser({ id, email, firstName, lastName });
};

export const generateCSRFState = (length: number) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const getRandomFloat = () =>
    window.crypto ? window.crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32 : Math.random();

  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(getRandomFloat() * characters.length)));
  }
  return result.join('');
};

export const websiteActivationsResetDateFunction = (subscription: any) => {
  return moment
    .utc(subscription.expiryDate)
    .add(1, 'day')
    .set({
      year: moment()
        .utc()
        .add(moment().utc().get('month') === 11 ? 1 : 0, 'month') // Add one month if in Dec
        .get('year'),
      month: moment()
        .utc()
        .isSameOrAfter(
          moment.utc(subscription.expiryDate).set({ year: moment().get('year'), month: moment().get('month') }),
        )
        ? moment().utc().add(1, 'month').get('month')
        : moment().utc().get('month'),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
};

export const websiteActivationsResetStartDateFunction = (websiteActivationsResetDate: any) =>
  moment.utc(websiteActivationsResetDate).subtract(1, 'month').subtract(1, 'day');

export const checkWebsiteActivations = (subscription: any) => {
  return (
    subscription.websites.filter((website: any) =>
      moment
        .utc(website.lastActivationDate)
        .isSameOrAfter(websiteActivationsResetStartDateFunction(websiteActivationsResetDateFunction(subscription))),
    ).length < subscription.plan.maxDomains
  );
};

export const checkActiveWebsites = (subscription: any) => {
  return (
    subscription.plan.maxDomains === -1 ||
    subscription.websites.filter((website: any) => website.active).length < subscription.plan.maxDomains
  );
};
