import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en.json';

const TestTranslations = (str: string) => {
  return '[Translated] ' + str;
};

i18n
  .use({
    type: 'postProcessor',
    name: 'TestTranslations',
    process: function (value: string) {
      return TestTranslations(value);
    },
  })
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    debug: process.env.REACT_APP_DEBUG_TRANSLATIONS === 'true',
    postProcess: process.env.REACT_APP_DEBUG_TRANSLATIONS === 'true' ? ['TestTranslations'] : [],
    resources: {
      en: en,
    },
    ns: ['SKDashboard', 'common', 'login'],
    defaultNS: 'SKDashboard',
    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false,
    },
    returnNull: false,
  });

export default i18n;
