import React, { useEffect } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { graphQLErrorHandler } from 'graphql/apollo';
import { SQSP_OAUTH_CONNECT_WEBSITE } from 'graphql/mutations/sqspOAuthConnectWebsite';
import OAuthConnecting from 'components/OAuthConnecting';
import i18next from 'i18next';

const OAuthConnectWebsite = () => {
  const location = useLocation();
  const history = useHistory();

  const [sqspOAuthConnectWebsite] = useMutation(SQSP_OAUTH_CONNECT_WEBSITE);

  useEffect(() => {
    const sqspOAuthState = localStorage.getItem('SQSPOAuthState');
    const subscriptionId = localStorage.getItem('subscriptionId');
    const websiteId = localStorage.getItem('websiteId');

    localStorage.removeItem('SQSPOAuthConnect');
    localStorage.removeItem('SQSPOAuthState');
    localStorage.removeItem('subscriptionId');
    localStorage.removeItem('websiteId');

    // Check State
    if (!sqspOAuthState || !subscriptionId) {
      graphQLErrorHandler({});
      history.push('/');
    } else {
      const { state, code, error } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder: decodeURIComponent,
      });

      if (error) {
        graphQLErrorHandler({
          title: i18next.t('oauth.accessDeniedErrorTitle'),
          message: i18next.t('oauth.connectWebsiteAccessDeniedErrorMessage'),
        });
        history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
      } else if (state === sqspOAuthState) {
        // Check CSRF Token matches
        // Connect SQSP Website
        sqspOAuthConnectWebsite({
          variables: {
            sqspAuthCode: code,
            subscriptionId: subscriptionId,
            websiteId: websiteId,
          },
        }).then(
          (success) => {
            const connectedWebsiteId = success.data.SQSPOAuthConnectWebsite.id;
            if (subscriptionId && connectedWebsiteId) {
              history.push(`/subscription/${subscriptionId}/website/${connectedWebsiteId}/settings`);
            } else {
              graphQLErrorHandler({});
              history.push('/');
            }
          },
          (error) => {
            if (error?.graphQLErrors?.[0]?.extensions?.exception?.data) {
              const {
                existingWebsiteError,
                subscriptionId: existingSubscriptionId,
                websiteId: existingWebsiteId,
              } = error.graphQLErrors[0].extensions.exception.data;

              if (existingWebsiteError === 'THIS_SUBSCRIPTION') {
                websiteId
                  ? history.push(
                      `/subscription/${subscriptionId}/website/${websiteId}/connect-existing-website/${existingSubscriptionId}/${existingWebsiteId}`,
                    )
                  : history.push(
                      `/subscription/${subscriptionId}/website/connect-existing-website/${existingSubscriptionId}/${existingWebsiteId}`,
                    );
              } else if (existingWebsiteError === 'THIS_ACCOUNT') {
                websiteId
                  ? history.push(
                      `/subscription/${subscriptionId}/website/${websiteId}/transfer-existing-website/${existingSubscriptionId}/${existingWebsiteId}`,
                    )
                  : history.push(
                      `/subscription/${subscriptionId}/website/transfer-existing-website/${existingSubscriptionId}/${existingWebsiteId}`,
                    );
              } else {
                graphQLErrorHandler(error);
                history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
              }
            } else {
              graphQLErrorHandler(error);
              history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
            }
          },
        );
      } else {
        graphQLErrorHandler({});
        history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
      }
    }
  }, [history, location.search, sqspOAuthConnectWebsite]);

  return <OAuthConnecting />;
};

export default OAuthConnectWebsite;
