import React from 'react';
import { Modal as ReactstrapModal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

const Modal: React.FunctionComponent<{
  isOpen: boolean;
  toggle: any;
  header: any;
  body: any;
  footer?: any;
}> = (props) => {
  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );
  return (
    <ReactstrapModal
      className="website-settings-modal"
      size="lg"
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered={true}
    >
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {props.header}
      </ModalHeader>
      <ModalBody>{props.body}</ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
    </ReactstrapModal>
  );
};

export default Modal;
