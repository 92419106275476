import { gql } from '@apollo/client';

import { SUBSCRIPTION_FIELDS, SUBSCRIPTION_RELATIONS } from '../fragments/subscription';

export const SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  ${SUBSCRIPTION_RELATIONS}
  query Subscription($id: ID!) {
    subscription(id: $id) {
      ...SubscriptionFields
      ...SubscriptionRelations
    }
  }
`;
