import { gql } from '@apollo/client';

import { WEBSITE_FIELDS, WEBSITE_RELATIONS } from '../fragments/website';

export const WEBSITE = gql`
  ${WEBSITE_FIELDS}
  ${WEBSITE_RELATIONS}
  query Website($id: ID!) {
    website(id: $id) {
      ...WebsiteFields
      ...WebsiteRelations
    }
  }
`;
