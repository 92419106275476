import { gql } from '@apollo/client';

import { WEBSITE_FIELDS, WEBSITE_RELATIONS } from '../fragments/website';
import { PLAN_FIELDS } from '../fragments/plan';
import { USER_FIELDS } from '../fragments/user';

export const SUBSCRIPTION_FIELDS = gql`
  fragment SubscriptionFields on Subscription {
    id
    expiryDate
    activationDate
    stripeSubscription
    stripePaymentMethod
    autoRenew
  }
`;

export const SUBSCRIPTION_RELATIONS = gql`
  ${USER_FIELDS}
  ${WEBSITE_FIELDS}
  ${WEBSITE_RELATIONS}
  ${PLAN_FIELDS}
  fragment SubscriptionRelations on Subscription {
    user {
      ...UserFields
    }
    websites {
      ...WebsiteFields
      ...WebsiteRelations
    }
    plan {
      ...PlanFields
    }
  }
`;
