import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { GET_STARTED_STEP_1_PATH } from 'routes';
import { useHistory } from 'react-router-dom';

const GetStarted: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <Card>
      <CardBody>
        <Row>
          <Col sm="12">
            <div className="sk-get-started d-flex flex-wrap justify-content-center align-items-center">
              <h3>{t('dashboard.getStartedText')}</h3>
              <Button
                id="sk-get-started-btn"
                data-test="get-started-button"
                className="btn-round btn-lg btn-success"
                onClick={() => {
                  history.push(GET_STARTED_STEP_1_PATH);
                }}
              >
                {t('dashboard.getStartedButton')}
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default GetStarted;
