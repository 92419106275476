import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const LOGIN = gql`
  ${ME_FIELDS}
  mutation Login($user: UsersPermissionsLoginInput!) {
    login(input: $user) {
      jwt
      user {
        ...MeFields
        role {
          id
          type
        }
      }
    }
  }
`;
