import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Row, Col } from 'reactstrap';
import { Chip, Divider, Grid } from '@mui/material';

import { PLAN_TYPE } from 'consts';
import { GET_STARTED_STEP_1_PATH } from 'routes';

import { WEBSITE } from 'graphql/queries/website';
import { SUBSCRIPTION } from 'graphql/queries/subscription';
import { UPDATE_WEBSITE } from 'graphql/mutations/updateWebsite';

import { graphQLErrorHandler } from 'graphql/apollo';
import DomainLink from 'components/DomainLink';

const TransferExistingWebsiteModal: React.FunctionComponent<{
  toggle: any;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const { subscriptionId, existingSubscriptionId, existingWebsiteId } = useParams<{
    subscriptionId: string;
    websiteId: string;
    existingSubscriptionId: string;
    existingWebsiteId: string;
  }>();

  const { loading: existingWebsiteLoading, data: existingWebsiteData } = useQuery(WEBSITE, {
    variables: { id: existingWebsiteId },
  });

  const { data: existingSubscriptionData, loading: existingSubscriptionLoading } = useQuery(SUBSCRIPTION, {
    variables: { id: existingSubscriptionId },
  });

  const { data: subscriptionData, loading: subscriptionLoading } = useQuery(SUBSCRIPTION, {
    variables: { id: subscriptionId },
  });

  const [updateWebsite] = useMutation(UPDATE_WEBSITE);

  const updateWebsiteHandler = (subscriptionId: any, websiteId: any, active: boolean) => {
    updateWebsite({
      variables: {
        website: {
          where: {
            id: websiteId,
          },
          data: {
            active,
          },
        },
      },
    }).then(
      (success) => {
        if (success.data.updateWebsite.website.lastSKRequestDate) {
          history.push('/');
        } else {
          history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
        }
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  if (
    existingWebsiteLoading ||
    existingSubscriptionLoading ||
    subscriptionLoading ||
    !existingWebsiteData ||
    !existingSubscriptionData ||
    !subscriptionData
  )
    return null;

  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );

  return (
    <Modal className="website-settings-modal" size="lg" isOpen={true} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {t('dashboard.existingWebsiteModalTitle')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <div className="text-center">
              <h3>{existingWebsiteData.website.title || existingWebsiteData.website.domain}</h3>
              {existingWebsiteData.website.title && (
                <p>
                  <DomainLink domain={existingWebsiteData.website.domain}></DomainLink>
                </p>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p className="text-center font-weight-bold">
                {existingWebsiteData.website.active &&
                  existingWebsiteData.website.lastSKRequestDate === null &&
                  t('dashboard.transferExistingWebsiteModalMessageInstall')}
                {existingWebsiteData.website.active &&
                  existingWebsiteData.website.lastSKRequestDate &&
                  t('dashboard.transferExistingWebsiteModalMessageWebsiteSettings')}
                {existingWebsiteData.website.active === false &&
                  t('dashboard.transferExistingWebsiteModalMessageRestore')}
              </p>
            </div>
            <Grid container>
              <Grid item xs>
                {/* Upgrade Subscription */}
                {subscriptionData.subscription.plan.type === PLAN_TYPE.FREE_TRIAL && (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="text-center">{t('dashboard.connectExistingWebsiteModalUpgradeMessage')}</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        className="btn-round float-right"
                        style={{ margin: 0 }}
                        color="purple"
                        onClick={() => {
                          history.push(GET_STARTED_STEP_1_PATH, {
                            currentSubscription: subscriptionData.subscription,
                          });
                        }}
                      >
                        <i className="fa fa-arrow-circle-up" />
                        {t('common.upgradePlan')}
                      </Button>
                    </div>
                  </>
                )}
                {subscriptionData.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="text-center">
                        {t('dashboard.transferExistingWebsiteModalMessageTransferOption', {
                          fromSubscription: existingSubscriptionData.subscription.plan.name,
                          toSubscription: subscriptionData.subscription.plan.name,
                        })}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        className="btn-round no-hide"
                        color="charcoal"
                        onClick={() =>
                          history.push(
                            `/subscription/${existingSubscriptionId}/website/${existingWebsiteId}/transfer/${subscriptionId}/confirm`,
                          )
                        }
                      >
                        {t('dashboard.transferExistingWebsiteModalButtonTransfer')}
                      </Button>
                    </div>
                  </>
                )}
              </Grid>
              <Divider orientation="vertical" flexItem>
                <Chip label="OR" />
              </Divider>
              <Grid item xs>
                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-center">
                    {existingWebsiteData.website.active &&
                      existingWebsiteData.website.lastSKRequestDate === null &&
                      t('dashboard.transferExistingWebsiteModalMessageInstallOption', {
                        fromSubscription: existingSubscriptionData.subscription.plan.name,
                      })}
                    {existingWebsiteData.website.active &&
                      existingWebsiteData.website.lastSKRequestDate &&
                      t('dashboard.transferExistingWebsiteModalMessageWebsiteSettingsOption', {
                        fromSubscription: existingSubscriptionData.subscription.plan.name,
                      })}
                    {existingWebsiteData.website.active === false &&
                      t('dashboard.transferExistingWebsiteModalMessageRestoreOption', {
                        fromSubscription: existingSubscriptionData.subscription.plan.name,
                      })}
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  {existingWebsiteData.website.active && existingWebsiteData.website.lastSKRequestDate === null && (
                    <Button
                      className="btn-round no-hide"
                      color="charcoal"
                      onClick={() =>
                        history.push(`/subscription/${existingSubscriptionId}/website/${existingWebsiteId}/settings`)
                      }
                    >
                      {t('dashboard.connectExistingWebsiteModalButtonInstall')}
                    </Button>
                  )}
                  {existingWebsiteData.website.active && existingWebsiteData.website.lastSKRequestDate && (
                    <Button
                      className="btn-round no-hide"
                      color="charcoal"
                      onClick={() =>
                        history.push(`/subscription/${existingSubscriptionId}/website/${existingWebsiteId}/settings`)
                      }
                    >
                      {t('dashboard.connectExistingWebsiteModalButtonWebsiteSettings')}
                    </Button>
                  )}
                  {existingWebsiteData.website.active === false && (
                    <Button
                      className="btn-round no-hide"
                      color="charcoal"
                      onClick={() => updateWebsiteHandler(existingSubscriptionId, existingWebsiteId, true)}
                    >
                      {t('dashboard.connectExistingWebsiteModalButtonRestore')}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.closeButton')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TransferExistingWebsiteModal;
