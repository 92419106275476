import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';

import { ReactComponent as SquarespaceLogo } from 'assets/img/sqsp-logo.svg';
import { toast } from 'react-toastify';
import { SQSP_OAUTH_ACTION } from 'consts';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SQSP_OAUTH_REQUEST } from 'graphql/mutations/sqspOAuthCreateRequest';
import { ME } from 'graphql/queries/me';

const SQSPConnectAccount: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(ME);
  const [createSQSPOAuthRequest] = useMutation(CREATE_SQSP_OAUTH_REQUEST);

  const connectAccountWithSQSPHandler = async () => {
    toast.dismiss();

    const generateCSRFState = (length: number) => {
      var result = [];
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const getRandomFloat = () =>
        window.crypto ? window.crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32 : Math.random();

      for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(getRandomFloat() * characters.length)));
      }
      return result.join('');
    };

    const sqspOAuthCSRFState = generateCSRFState(28);
    localStorage.setItem('SQSPOAuthState', sqspOAuthCSRFState);
    localStorage.setItem('SQSPOAuthConnect', SQSP_OAUTH_ACTION.CONNECT_ACCOUNT);

    await createSQSPOAuthRequest({
      variables: {
        input: {
          data: {
            csrfToken: sqspOAuthCSRFState,
            redirectURI: process.env.REACT_APP_DASHBOARD_URL,
            action: SQSP_OAUTH_ACTION.CONNECT_ACCOUNT,
          },
        },
      },
    });

    var params = new URLSearchParams({
      client_id: process.env.REACT_APP_SQSP_OAUTH_CLIENT_ID || '',
      redirect_uri: process.env.REACT_APP_SQSP_OAUTH_REDIRECT_URI || '',
      scope: 'profile.read',
      state: sqspOAuthCSRFState,
      access_type: 'offline',
    });

    const url = `${process.env.REACT_APP_SQSP_OAUTH_AUTHORIZE_URL || ''}?${params.toString()}`;
    window.location.assign(url);
  };

  if (loading || !data) return null;

  return (
    <Row>
      <Col sm="12">
        <h5>{t('account.connectWithSQSP')}</h5>
        {!data.me.sqspConnected && (
          <>
            <div className="squarespace-btn squarespace-connect">
              <Button onClick={connectAccountWithSQSPHandler}>
                <SquarespaceLogo />
                {t('account.connectWithSQSP')}
              </Button>
            </div>
            <p className="text-center">{t('account.connectWithSQSPDescription')}</p>
          </>
        )}
        {data.me.sqspConnected && (
          <div className="text-center">
            <div className="squarespace-btn squarespace-connect">
              <span className="squarespace-connected">
                <SquarespaceLogo />
                {t('account.connectedWithSQSP')}
              </span>
            </div>
            <p>
              To manage your connected apps, go to your{' '}
              <a
                href={`https://account.squarespace.com/settings/security/apps`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Connected Apps
              </a>{' '}
              in Squarespace.
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SQSPConnectAccount;
