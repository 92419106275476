import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormText,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';

import { UPDATE_WEBSITE } from 'graphql/mutations/updateWebsite';

import DomainLink from 'components/DomainLink';
import { SUBSCRIPTION } from 'graphql/queries/subscription';

const InactiveWebsitesModal: React.FunctionComponent<{
  toggle: any;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchFilter, setSearchFilter] = useState('');

  const searchFilterFunction = (website: any) => {
    if (searchFilter === '') return true;
    try {
      return (
        website.id === searchFilter ||
        (website.title && website.title.search(new RegExp(searchFilter, 'i')) !== -1) ||
        (website.domain && website.domain.search(new RegExp(searchFilter, 'i')) !== -1)
      );
    } catch (e) {
      return false;
    }
  };

  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  const { data, loading } = useQuery(SUBSCRIPTION, {
    variables: { id: subscriptionId },
  });

  const [updateWebsite] = useMutation(UPDATE_WEBSITE);

  const updateWebsiteHandler = (subscriptionId: any, websiteId: any, active: boolean) => {
    updateWebsite({
      variables: {
        website: {
          where: {
            id: websiteId,
          },
          data: {
            active,
          },
        },
      },
    }).then(
      (success) => {
        if (success.data.updateWebsite.website.lastSKRequestDate) {
          history.push('/');
        } else {
          history.push(`/subscription/${subscriptionId}/website/${websiteId}/settings`);
        }
      },
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  const closeBtn = (
    <Button className="btn-round close-btn" onClick={props.toggle}>
      <i className="fa fa-times"></i>
    </Button>
  );

  if (loading || !data) return null;

  return (
    <Modal className="website-settings-modal" size="lg" isOpen={true} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag={'h2'} close={closeBtn}>
        {t('dashboard.connectWebsiteModalTitle')}
      </ModalHeader>
      <ModalBody>
        <Row className="website-list-search">
          <Col sm="12">
            <h5>Search</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <FormGroup style={{ paddingTop: '10px' }}>
                <Input
                  type="search"
                  name="search"
                  id="website-list-search-filter"
                  placeholder="website title or domain"
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                />
                <FormText color="muted" style={{ float: 'right' }}>
                  Showing{' '}
                  {
                    data.subscription.websites.filter((website: any) => !website.active).filter(searchFilterFunction)
                      .length
                  }{' '}
                  of {data.subscription.websites.filter((website: any) => !website.active).length}
                </FormText>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <div id="website-list">
          {data.subscription.websites
            .filter((website: any) => !website.active)
            .filter(searchFilterFunction)
            .map((website: any) => {
              return (
                <Row key={website.id} className="website-list-row">
                  <Col lg="6" xl="8">
                    <p>
                      <span
                        className={
                          website.lastSKRequestDate ? 'statusIndicator connected' : 'statusIndicator connecting'
                        }
                      ></span>

                      <b>{website.title || website.domain}</b>
                    </p>
                    {website.title && (
                      <p style={{ marginLeft: '30px' }}>
                        <DomainLink domain={website.domain}></DomainLink>
                      </p>
                    )}
                  </Col>
                  <Col className="website-list-button-col">
                    <Button
                      className="btn-round"
                      color="success"
                      onClick={() => updateWebsiteHandler(subscriptionId, website.id, true)}
                    >
                      {t('dashboard.connectInactiveWebsiteButton')}
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Button className="btn-round no-hide" color="ocean" onClick={() => props.toggle()}>
            {t('common.closeButton')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InactiveWebsitesModal;
