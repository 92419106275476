import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, FormGroup, Input, Button, Collapse } from 'reactstrap';

const InstallationCode: React.FunctionComponent<{
  website: any;
  refetch: any;
  collapseIsOpen: any;
  setCollapseIsOpen: any;
}> = (props) => {
  const { t } = useTranslation();

  const toggleCollapse = () => props.setCollapseIsOpen(!props.collapseIsOpen);

  const apiURL =
    process.env.NODE_ENV !== 'production'
      ? `
    sk.dataset.apiurl = "${process.env.REACT_APP_API_URL?.replace('/graphql', '')}";`
      : '';

  const installationScript = `<!-- SquareKicker.com | Extension | START --><script>
  if (window.self !== window.top &&
      window.top.Static.SQUARESPACE_CONTEXT.authenticatedAccount) {
    var sk = document.createElement('script');
    sk.type = 'text/javascript';
    sk.id = "squarekicker";
    sk.src = "${process.env.REACT_APP_UI_URL}";
    sk.dataset.sktoken = "${props.website.sk_token.token}";${apiURL}
    document.querySelector('head').appendChild(sk);
  }
</script><!-- SquareKicker.com | Extension | END -->`;

  const [step1Complete, setStep1Complete] = useState(false);
  const [copyToClipboardText, setCopyToClipboardText] = useState(
    t('dashboard.websiteSettingsModalInstallationCopyToClipboard'),
  );

  const [step2Complete, setStep2Complete] = useState(false);
  const [openCodeInjectionText, setOpenCodeInjectionText] = useState(
    t('dashboard.websiteSettingsModalInstallationCodeInjection'),
  );

  const [step3Complete, setStep3Complete] = useState(false);
  const [verifyText, setVerifyText] = useState(t('dashboard.websiteSettingsModalInstallationVerify'));

  const [verificationFailed, setVerificationFailed] = useState(false);

  const verifyConnection = () => {
    setVerifyText(t('dashboard.websiteSettingsModalInstallationVerifying'));
    props.refetch().then(
      (success: any) => {
        if (success.data.website.lastSKRequestDate) {
          setVerifyText(t('dashboard.websiteSettingsModalInstallationVerified'));
          setVerificationFailed(false);
          setStep3Complete(true);
          props.setCollapseIsOpen(false);
        } else {
          setVerificationFailed(true);
          setStep3Complete(false);
          setVerifyText(t('dashboard.websiteSettingsModalInstallationTryAgain'));
        }
      },
      () => {
        setVerificationFailed(true);
        setStep3Complete(false);
        setVerifyText(t('dashboard.websiteSettingsModalInstallationTryAgain'));
      },
    );
  };

  if (!props?.website?.sk_token?.token) return null;

  return (
    <Row>
      <Col sm="12">
        <h5 onClick={() => toggleCollapse()}>
          {t('dashboard.websiteSettingsModalInstallation')}{' '}
          <i className={props.collapseIsOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
        </h5>
        <Collapse isOpen={props.collapseIsOpen}>
          <h5 className="installation-step-icon">1</h5>
          <h5 className="installation-step-title">{t('dashboard.websiteSettingsModalInstallationStep1Title')}</h5>
          <div className="installation-steps">
            <p>{t('dashboard.websiteSettingsModalInstallationStep1Text')}</p>
            <FormGroup>
              <Input
                type="textarea"
                name="script"
                value={installationScript}
                onClick={() => {
                  navigator.clipboard.writeText(installationScript);
                  setCopyToClipboardText(t('dashboard.websiteSettingsModalInstallationCopied'));
                  setStep1Complete(true);
                }}
                readOnly
              />
            </FormGroup>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                className="btn-round no-hide"
                color="info"
                onClick={() => {
                  navigator.clipboard.writeText(installationScript);
                  setCopyToClipboardText(t('dashboard.websiteSettingsModalInstallationCopied'));
                  setStep1Complete(true);
                }}
              >
                {copyToClipboardText}
                {step1Complete && ' '}
                {step1Complete && <i className="fa fa-check"></i>}
              </Button>
            </div>
          </div>
          <h5 className="installation-step-icon">2</h5>
          <h5 className="installation-step-title">{t('dashboard.websiteSettingsModalInstallationStep2Title')}</h5>
          <div className="installation-steps">
            <p>{t('dashboard.websiteSettingsModalInstallationStep2Text')}</p>
            <p>
              <strong>{t('dashboard.websiteSettingsModalInstallationStep2TextNote')}</strong>
            </p>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                className="btn-round no-hide"
                color="info"
                onClick={() => {
                  window.open(`https://${props.website.domain}/config/settings/advanced/code-injection`, '_blank');
                  setOpenCodeInjectionText(t('dashboard.websiteSettingsModalInstallationCodeInjectionOpened'));
                  setStep2Complete(true);
                }}
              >
                {openCodeInjectionText}
                {step1Complete && ' '}
                {step2Complete && <i className="fa fa-check"></i>}
              </Button>
            </div>
          </div>

          <h5 className="installation-step-icon">3</h5>
          <h5 className="installation-step-title">{t('dashboard.websiteSettingsModalInstallationStep3Title')}</h5>
          <div className="installation-steps">
            <p>{t('dashboard.websiteSettingsModalInstallationStep3Text')}</p>
            {verificationFailed && (
              <p className="text-danger">
                <Trans i18nKey="dashboard.websiteSettingsModalInstallationVerificationFailed">
                  <strong>We were unable to verify the connection to your site.</strong>
                  <br />
                  Please make sure the above code is copied in full to the Header section of the Code Injection area of
                  Squarespace and you have clicked save before you try again. If you need some help, please check out
                  the <a href="/help">help page</a> or contact SquareKicker Support and we can give you a hand.
                </Trans>
              </p>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <Button className="btn-round no-hide" color="info" onClick={() => verifyConnection()}>
                {verifyText} {step3Complete && ' '}
                {step3Complete && <i className="fa fa-check"></i>}
              </Button>
            </div>
          </div>
        </Collapse>
      </Col>
    </Row>
  );
};

export default InstallationCode;
