import React, { useEffect } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';

import { graphQLErrorHandler } from 'graphql/apollo';
import { SQSP_OAUTH_CONNECT_ACCOUNT } from 'graphql/mutations/sqspOAuthConnectAccount';
import OAuthConnecting from 'components/OAuthConnecting';
import i18next from 'i18next';

const OAuthConnectAccount = () => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();

  const [sqspOAuthConnectAccount] = useMutation(SQSP_OAUTH_CONNECT_ACCOUNT);

  useEffect(() => {
    const sqspOAuthState = localStorage.getItem('SQSPOAuthState');

    localStorage.removeItem('SQSPOAuthConnect');
    localStorage.removeItem('SQSPOAuthState');

    // Check State
    if (!sqspOAuthState) {
      graphQLErrorHandler({});
      history.push('/');
    } else {
      const { state, code, error } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder: decodeURIComponent,
      });

      if (error) {
        graphQLErrorHandler({
          title: i18next.t('oauth.accessDeniedErrorTitle'),
          message: i18next.t('oauth.connectAccountAccessDeniedErrorMessage'),
        });
        history.push(`/account`);
      } else if (state === sqspOAuthState) {
        // Check CSRF Token matches
        // Connect SQSP Account
        sqspOAuthConnectAccount({
          variables: {
            sqspAuthCode: code,
          },
        }).then(
          (success) => {
            history.push('/account');
          },
          (error) => {
            graphQLErrorHandler(error);
            history.push('/account');
          },
        );
      } else {
        graphQLErrorHandler({});
        history.push('/account');
      }
    }
  }, [history, location.search, sqspOAuthConnectAccount, client]);

  return <OAuthConnecting />;
};

export default OAuthConnectAccount;
