import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const SQSP_OAUTH_LOGIN = gql`
  ${ME_FIELDS}
  mutation SQSPOAuthLogin($sqspAuthCode: String!) {
    SQSPOAuthLogin(sqspAuthCode: $sqspAuthCode) {
      jwt
      user {
        ...MeFields
        role {
          id
          type
        }
      }
    }
  }
`;
