import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';

const DashboardSidebar: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const activeRoute = (routeName: string, before: string = '', after: string = '') => {
    if (before && location.pathname === before) return 'active-before';
    if (after && location.pathname === after) return 'active-after';
    return location.pathname === routeName ? 'active' : '';
  };

  return (
    <div className="sidebar">
      <div className="logo">
        <a href={process.env.REACT_APP_DASHBOARD_URL}>
          <img src={require('assets/img/sk-logo-white.png')} alt="sk-logo" data-test="sk-logo" />
        </a>
      </div>

      <div className="sidebar-wrapper d-flex flex-column justify-content-between">
        <Nav>
          <li className={activeRoute('', '/', '')}>
            <div></div>
          </li>
          <li data-test="sidebar-dashboard" className={activeRoute('/', '/account', '')}>
            <NavLink to={'/'} activeClassName="">
              <i className="nc-icon nc-layout-11" />
              <p>{t('dashboardSidebar.dashboardPageLink')}</p>
            </NavLink>
          </li>
          <li data-test="sidebar-account" className={activeRoute('/account', '/subscriptions', '/')}>
            <NavLink to={'/account'} activeClassName="">
              <i className="nc-icon nc-single-02" />
              <p>{t('dashboardSidebar.accountPageLink')}</p>
            </NavLink>
          </li>
          <li data-test="sidebar-subscriptions" className={activeRoute('/subscriptions', '', '/account')}>
            <NavLink to={'/subscriptions'} activeClassName="">
              <i className="nc-icon nc-credit-card" />
              <p>{t('dashboardSidebar.subscriptionsPageLink')}</p>
            </NavLink>
          </li>
          <li className={activeRoute('', '', '/subscriptions')}>
            <div></div>
          </li>
        </Nav>

        <Nav>
          <li className={activeRoute('', '/help', '')}>
            <div></div>
          </li>
          <li data-test="sidebar-help" className={activeRoute('/help')}>
            <NavLink to={'/help'} activeClassName="">
              <i className="nc-icon nc-alert-circle-i" />
              <p>{t('dashboardSidebar.helpPageLink')}</p>
            </NavLink>
          </li>
          <li className={activeRoute('', '', '/help')}>
            <div></div>
          </li>
        </Nav>
      </div>
    </div>
  );
};

export default DashboardSidebar;
