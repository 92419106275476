import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthNavbar from 'components/Navbars/AuthNavbar';
import AuthSidebar from 'components/Sidebars/AuthSidebar';
import Login from 'views/Login';
import SignupLanding from 'views/SignupLanding';
import SignupWithEmail from 'views/SignupWithEmail';
import SignupWithSquarespace from 'views/SignupWithSquarespace';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import OAuthLogin from 'views/OAuthLogin';
import OAuthRegister from 'views/OAuthRegister';
import OAuthConnectAccount from 'views/OAuthConnectAccount';
import OAuthConnectWebsite from 'views/OAuthConnectWebsite';

const AuthLayout: React.FunctionComponent = () => {
  return (
    <div className="wrapper auth-layout">
      <AuthSidebar />
      <div className="main-panel">
        <AuthNavbar />
        <Switch>
          <Route path="/login" component={Login}></Route>
          <Route path="/signup" component={SignupLanding}></Route>
          <Route path="/signup-with-email" component={SignupWithEmail}></Route>
          <Route path="/signup-with-squarespace" component={SignupWithSquarespace}></Route>
          <Route path="/forgot" component={ForgotPassword}></Route>
          <Route path="/reset/:code" component={ResetPassword}></Route>
          <Route exact path="/oauth/connect/login" component={OAuthLogin}></Route>
          <Route exact path="/oauth/connect/signup" component={OAuthRegister}></Route>
          <Route exact path="/oauth/connect/account" component={OAuthConnectAccount}></Route>
          <Route exact path="/oauth/connect/website" component={OAuthConnectWebsite}></Route>
        </Switch>
      </div>
    </div>
  );
};

export default AuthLayout;
