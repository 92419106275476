import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Button, Input, Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { SQSP_OAUTH_ACTION } from 'consts';

import { generateCSRFState } from 'functions';
import { CREATE_SQSP_OAUTH_REQUEST } from 'graphql/mutations/sqspOAuthCreateRequest';

import { ReactComponent as SquarespaceLogo } from 'assets/img/sqsp-logo.svg';
import { ReactComponent as SquareKickerLogo } from 'assets/img/sk-logo.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';

const SignupWithSquarespace: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [createSQSPOAuthRequest] = useMutation(CREATE_SQSP_OAUTH_REQUEST);

  const onSubmitHandler = async (values: any) => {
    toast.dismiss();

    const sqspOAuthCSRFState = generateCSRFState(28);
    localStorage.setItem('SQSPOAuthState', sqspOAuthCSRFState);
    localStorage.setItem('SQSPOAuthConnect', SQSP_OAUTH_ACTION.CREATE_ACCOUNT);

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.email,
      email: values.email,
    };

    localStorage.setItem('SQSPUserData', btoa(encodeURIComponent(JSON.stringify(user))));

    await createSQSPOAuthRequest({
      variables: {
        input: {
          data: {
            csrfToken: sqspOAuthCSRFState,
            redirectURI: process.env.REACT_APP_DASHBOARD_URL,
            action: SQSP_OAUTH_ACTION.CREATE_ACCOUNT,
          },
        },
      },
    });

    var params = new URLSearchParams({
      client_id: process.env.REACT_APP_SQSP_OAUTH_CLIENT_ID || '',
      redirect_uri: process.env.REACT_APP_SQSP_OAUTH_REDIRECT_URI || '',
      scope: 'profile.read',
      state: sqspOAuthCSRFState,
      access_type: 'offline',
    });

    const url = `${process.env.REACT_APP_SQSP_OAUTH_AUTHORIZE_URL || ''}?${params.toString()}`;
    window.location.assign(url);
  };

  return (
    <div className="register-page register-with-squarespace">
      <Container>
        <div className="auth-wrapper d-flex flex-column justify-content-center m-auto">
          <div className="logo">
            <a href={process.env.REACT_APP_DASHBOARD_URL} className="simple-text logo-normal" data-test="skLogoImage">
              <img src={require('assets/img/sk-logo-black.png')} alt="SquareKicker" />
            </a>
          </div>
          <div className="auth-form">
            <>
              <div data-test="squareKickerPlusSquarespaceLogo" style={{ marginBottom: '20px' }}>
                <SquareKickerLogo width="33px" height="33px" />
                <div style={{ marginLeft: '6px', marginRight: '6px', display: 'inline' }}>
                  <PlusIcon width="22px" height="16px" />
                </div>
                <SquarespaceLogo width="33px" height="33px" />
              </div>
              <h2 data-test="signUpWithSquarespaceTitle">{t('signup.signUpWithSquarespace')}</h2>
              <p data-test="signUpWithSquarespaceText" className="text-left">
                {t('signup.signUpWithSquarespaceText')}
              </p>
            </>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                acceptTerms: false,
                registerForm: '',
              }}
              validate={(values) => {
                const errors: any = {};

                if (!values.firstName) {
                  errors.firstName = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }

                if (!values.lastName) {
                  errors.lastName = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                }
                if (!values.email) {
                  errors.email = '';
                  errors.registerForm = t('formValidation.forgotSomething');
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = errors.email = t('formValidation.invalidEmail');
                }

                if (!values.acceptTerms) {
                  errors.acceptTerms = t('formValidation.acceptTermsAndConditions');
                }

                return errors;
              }}
              onSubmit={onSubmitHandler}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup className={errors.firstName !== undefined ? 'has-danger' : ''}>
                        <Label for="firstName" data-test="firstNameLabel">
                          {t('signup.firstName')}
                        </Label>
                        <Field name="firstName" as={Input} />
                        <ErrorMessage
                          name="firstName"
                          component={() => <p className="text-danger">{errors.firstName}</p>}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={errors.lastName !== undefined ? 'has-danger' : ''}>
                        <Label for="lastName" data-test="lastNameLabel">
                          {t('signup.lastName')}
                        </Label>
                        <Field name="lastName" as={Input} />
                        <ErrorMessage
                          name="lastName"
                          component={() => <p className="text-danger">{errors.lastName}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className={errors.email !== undefined ? 'has-danger' : ''}>
                    <Label for="email" data-test="emailLabel">
                      {t('signup.email')}
                    </Label>
                    <Field type="email" name="email" as={Input} noValidate />
                    <ErrorMessage name="email" component={() => <p className="text-danger">{errors.email}</p>} />
                  </FormGroup>

                  <FormGroup check>
                    <Label check data-test="acceptTermsLabel">
                      <Field type="checkbox" name="acceptTerms" className="form-check-input" />
                      <span className="form-check-sign"></span>
                      <Trans i18nKey="signup.termsAndConditions">
                        I agree to{' '}
                        <a
                          href="https://squarekicker.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-test="termsAndConditionsLink"
                        >
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a
                          href="https://squarekicker.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-test="privacyPolicyLink"
                        >
                          Privacy Policy
                        </a>
                      </Trans>
                    </Label>
                    <ErrorMessage
                      name="acceptTerms"
                      component={() => (
                        <p className="text-danger" data-test="acceptTermsErrorMessage">
                          {errors.acceptTerms}
                        </p>
                      )}
                    />
                  </FormGroup>

                  <ErrorMessage
                    name="registerForm"
                    component={() => (
                      <Alert color="danger" data-test="registerFormErrorMessage">
                        {errors.registerForm}
                      </Alert>
                    )}
                  />
                  <Row>
                    <Col md="auto">
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn-round"
                        color="primary"
                        data-test="signUpButton"
                      >
                        {t('signup.signUp')}
                      </Button>
                    </Col>
                    <Col md="auto" className="login-link" data-test="logInLink">
                      <span>
                        <Trans i18nKey="signup.logIn">
                          Already a member? <Link to="/login">Log In</Link>
                        </Trans>
                      </span>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignupWithSquarespace;
