import React from 'react';
import { useQuery } from '@apollo/client';
import { DASHBOARD_CONTENT } from 'graphql/queries/dashboardContent';

const DashboardContent: React.FunctionComponent<any> = () => {
  const { data, loading } = useQuery(DASHBOARD_CONTENT);

  if (loading || !data?.dashboardContent) return null;

  return <div dangerouslySetInnerHTML={{ __html: data.dashboardContent.content }} />;
};

export default DashboardContent;
