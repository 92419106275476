import { gql } from '@apollo/client';

import { WEBSITE_FIELDS, WEBSITE_RELATIONS } from '../fragments/website';

export const UPDATE_WEBSITE = gql`
  ${WEBSITE_FIELDS}
  ${WEBSITE_RELATIONS}
  mutation UpdateWebsite($website: updateWebsiteInput!) {
    updateWebsite(input: $website) {
      website {
        ...WebsiteFields
        ...WebsiteRelations
      }
    }
  }
`;
