import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const SQSP_OAUTH_REGISTER = gql`
  ${ME_FIELDS}
  mutation SQSPOAuthRegister(
    $sqspAuthCode: String!
    $firstName: String!
    $lastName: String!
    $username: String!
    $email: String!
  ) {
    SQSPOAuthRegister(
      sqspAuthCode: $sqspAuthCode
      firstName: $firstName
      lastName: $lastName
      username: $username
      email: $email
    ) {
      jwt
      user {
        ...MeFields
      }
    }
  }
`;
