import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';
import { toast } from 'react-toastify';
import { ME } from 'graphql/queries/me';
import { UPDATE_USER } from 'graphql/mutations/updateUser';

const EmailSettingsForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery(ME);
  const [updateUser] = useMutation(UPDATE_USER);

  if (loading || !data) return null;

  const initialValues = {
    email: data.me.email || '',
  };

  const onSubmitHandler = async (values: any, { resetForm }: any) => {
    toast.dismiss();

    let user = {
      username: values.email,
      email: values.email,
    };

    await updateUser({
      variables: {
        user: {
          where: {
            id: data.me.id,
          },
          data: user,
        },
      },
    }).then(
      (success) => {
        refetch().then((refetchSuccess) => {
          const newValues = {
            email: refetchSuccess.data.me.email || '',
          };

          resetForm({ values: newValues });
        });
      },
      (error) => {
        graphQLErrorHandler(error);
        resetForm({ values });
      },
    );
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};

        if (!values.email) {
          errors.email = t('formValidation.forgotSomething');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = t('formValidation.invalidEmail');
        }

        return errors;
      }}
      onSubmit={onSubmitHandler}
    >
      {({ dirty, submitCount, errors, resetForm }) => (
        <Form>
          <fieldset disabled={Object.keys(errors).length === 0 && submitCount > 0}>
            <Row>
              <Col sm="12">
                <h5 data-test="account-email-setting-title">{t('account.emailSettingsSectionHeader')}</h5>
                <FormGroup className={errors.email !== undefined ? 'has-danger' : ''}>
                  <Label for="email" data-test="account-email-address-label">
                    {t('account.emailAddress')}
                  </Label>
                  <Field type="email" name="email" data-test="account-email-address-input" as={Input} />
                  <ErrorMessage
                    name="email"
                    component={() => (
                      <p data-test="account-email-setting-error" className="text-danger">
                        {errors.email}
                      </p>
                    )}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    data-test="update-email-button"
                    type="submit"
                    className="btn-round"
                    color="primary"
                  >
                    {t('account.updateEmailButton')}
                  </Button>
                  <Button
                    disabled={Object.keys(errors).length === 0 && (!dirty || submitCount > 0)}
                    onClick={() => resetForm({ values: initialValues })}
                    data-test="update-email-cancel-button"
                    className="btn-round"
                    color="danger"
                  >
                    {t('common.cancelButton')}
                  </Button>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

export default EmailSettingsForm;
