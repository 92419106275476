import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { PLAN_TYPE } from 'consts';
import { useHistory } from 'react-router-dom';
import { GET_STARTED_STEP_1_PATH } from 'routes';

const WebsiteMenu: React.FunctionComponent<{
  subscription: any;
  website: any;
  showWebsiteSettingsModal: any;
  enableTransferWebsite: Function;
  showTransferWebsitesModal: any;
  showConfirmRemoveWebsiteModal: any;
}> = (props) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <h2 className="websiteMenu">
      <UncontrolledDropdown>
        <DropdownToggle caret>⋮</DropdownToggle>
        <DropdownMenu end>
          {(props.subscription.stripeSubscription?.status !== 'canceled' || props.website.lastSKRequestDate) && (
            <DropdownItem onClick={() => props.showWebsiteSettingsModal(props.subscription, props.website)}>
              {props.website.lastSKRequestDate ? t('dashboard.websiteEdit') : t('dashboard.websiteStatusConnect')}
            </DropdownItem>
          )}
          {props.subscription.stripeSubscription?.status !== 'canceled' && props.website.domain && (
            <DropdownItem
              onClick={() =>
                window.open('https://' + props.website.domain + '/config/settings/advanced/code-injection', '_blank')
              }
            >
              {t('dashboard.websiteSettingsModalInstallationCodeInjection')}
            </DropdownItem>
          )}
          {props.enableTransferWebsite(props.subscription) && (
            <DropdownItem onClick={() => props.showTransferWebsitesModal(props.subscription.id, props.website.id)}>
              {t('dashboard.websiteMenuTransfer')}
            </DropdownItem>
          )}
          {(props.subscription.plan.type === PLAN_TYPE.AGENCY || props.subscription.plan.type === PLAN_TYPE.DESIGNER) &&
            (props.subscription.stripeSubscription?.status !== 'canceled' || !props.website.lastSKRequestDate) && (
              <DropdownItem onClick={() => props.showConfirmRemoveWebsiteModal(props.website)}>
                {t('dashboard.websiteMenuRemove')}
              </DropdownItem>
            )}

          {props.subscription.stripeSubscription?.status === 'canceled' &&
            props.subscription.plan.type === PLAN_TYPE.FREE_TRIAL && (
              <DropdownItem
                onClick={() => {
                  history.push(GET_STARTED_STEP_1_PATH, {
                    currentSubscription: props.subscription,
                  });
                }}
              >
                {t('common.upgradePlan')}
              </DropdownItem>
            )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </h2>
  );
};

export default WebsiteMenu;
