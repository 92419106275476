import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { CardBody, Card, Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { graphQLErrorHandler } from 'graphql/apollo';

import { SUBSCRIPTIONS } from 'graphql/queries/subscriptions';
import { UPDATE_WEBSITE } from 'graphql/mutations/updateWebsite';
import { GET_STARTED_STEP_1_PATH } from 'routes';
import { BILLING_CYCLE, PLAN_TYPE } from 'consts';

import GetStarted from './components/GetStarted';
import SubscriptionSummary from './components/SubscriptionSummary';
import DomainLink from 'components/DomainLink';
import WebsiteMenu from './components/WebsiteMenu';
import WebsiteSettingsModal from './components/WebsiteSettingsModal';
import ConnectExistingWebsiteModal from './components/ConnectExistingWebsiteModal';
import TransferExistingWebsiteModal from './components/TransferExistingWebsiteModal';
import InactiveWebsitesModal from './components/InactiveWebsitesModal';
import TransferWebsiteModal from './components/TransferWebsiteModal';
import TransferWebsiteConfirmationModal from './components/TransferWebsiteConfirmationModal';
import RemoveWebsiteConfirmationModal from './components/RemoveWebsiteConfirmationModal';
import { checkWebsiteActivations } from 'functions';
import ActivationsReachedTooltip from 'components/ActivationsReachedTooltip';

const Subscriptions: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const { data, loading, refetch, startPolling, stopPolling } = useQuery(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only',
  });

  const [updateWebsite] = useMutation(UPDATE_WEBSITE);

  const [tooltipOpen, setTooltipOpen] = useState('');

  // Website Settings Modal
  const websiteSettingsModalToggle = () => {
    history.push('/');
    refetch();
  };

  useEffect(() => {
    return history.listen((location: any) => {
      if (location.pathname === '/') stopPolling();
    });
  }, [history, stopPolling]);

  const [websiteSettingsModalState, setWebsiteSettingsModalState] = useState({
    subscription: {} as any,
    startPolling: startPolling as any,
  });

  const showWebsiteSettingsModal = (subscription: any, website: any) => {
    history.push(`/subscription/${subscription.id}/website/${website.id}/settings`);
    setWebsiteSettingsModalState({ subscription: subscription, startPolling });
  };

  // Inactive Website Modal
  const inactiveWebsitesModalToggle = () => {
    history.push('/');
    refetch();
  };

  const showInactiveWebsitesModal = (subscriptionId: any) => {
    history.push('/subscription/' + subscriptionId + '/connect-inactive-website');
  };

  // Transfer Website Modal
  const transferWebsitesModalToggle = () => {
    history.push('/');
    refetch();
  };

  const showTransferWebsitesModal = (subscriptionId: string, websiteId: string) => {
    history.push('/subscription/' + subscriptionId + '/website/' + websiteId + '/transfer');
  };

  // Confirm Remove Website Modal
  const confirmRemoveWebsiteModalToggle = () => {
    history.push('/');
  };

  const [confirmRemoveWebsiteModalState, setConfirmRemoveWebsiteModalState] = useState({
    website: {} as any,
  });

  const showConfirmRemoveWebsiteModal = (website: any) => {
    history.push('/website/' + website.id + '/remove/confirm');
    setConfirmRemoveWebsiteModalState({ website });
  };

  // Confirm Transfer Website Modal
  const confirmTransferWebsiteModalToggle = () => {
    history.push('/');
    refetch();
  };

  // Connect New Website Modal
  const connectNewWebsite = (subscription: any) => {
    history.push(`/subscription/${subscription.id}/website/connect`);
    setWebsiteSettingsModalState({ subscription, startPolling });
  };

  const removeWebsite = (websiteId: any) => {
    updateWebsite({
      variables: {
        website: {
          where: {
            id: websiteId,
          },
          data: {
            active: false,
          },
        },
      },
    }).then(
      (success) => {},
      (error) => {
        graphQLErrorHandler(error);
      },
    );
  };

  if (loading || !data?.subscriptions) return null;

  const enableTransferWebsite = (currentSubscription: any) => {
    return data.subscriptions.some((subscription: any) => {
      return (
        currentSubscription.id !== subscription.id &&
        subscription.stripeSubscription?.status !== 'canceled' &&
        (subscription.plan.type === PLAN_TYPE.DESIGNER || subscription.plan.type === PLAN_TYPE.AGENCY)
      );
    });
  };

  return (
    <>
      <Route
        exact
        path={[
          '/subscription/:subscriptionId/website/:websiteId/settings',
          '/subscription/:subscriptionId/website/connect',
        ]}
      >
        <WebsiteSettingsModal toggle={websiteSettingsModalToggle} state={websiteSettingsModalState} />
      </Route>

      <Route
        exact
        path={[
          '/subscription/:subscriptionId/website/:websiteId/connect-existing-website/:existingSubscriptionId/:existingWebsiteId',
          '/subscription/:subscriptionId/website/connect-existing-website/:existingSubscriptionId/:existingWebsiteId',
        ]}
      >
        <ConnectExistingWebsiteModal toggle={websiteSettingsModalToggle} />
      </Route>
      <Route
        exact
        path={[
          '/subscription/:subscriptionId/website/:websiteId/transfer-existing-website/:existingSubscriptionId/:existingWebsiteId',
          '/subscription/:subscriptionId/website/transfer-existing-website/:existingSubscriptionId/:existingWebsiteId',
        ]}
      >
        <TransferExistingWebsiteModal toggle={websiteSettingsModalToggle} />
      </Route>

      <Route exact path={['/subscription/:subscriptionId/connect-inactive-website']}>
        <InactiveWebsitesModal toggle={inactiveWebsitesModalToggle} />
      </Route>
      <Route exact path={['/subscription/:subscriptionId/website/:websiteId/transfer']}>
        <TransferWebsiteModal toggle={transferWebsitesModalToggle} />
      </Route>
      <Route exact path={['/subscription/:fromSubscriptionId/website/:websiteId/transfer/:toSubscriptionId/confirm']}>
        <TransferWebsiteConfirmationModal toggle={confirmTransferWebsiteModalToggle} />
      </Route>
      <Route exact path={['/website/:websiteId/remove/confirm']}>
        <RemoveWebsiteConfirmationModal
          toggle={confirmRemoveWebsiteModalToggle}
          state={confirmRemoveWebsiteModalState}
          confirmationFunction={removeWebsite}
        />
      </Route>

      {data.subscriptions.length === 0 && <GetStarted />}
      {Array.from(data.subscriptions)
        .reverse()
        .map((subscription: any) => {
          return (
            <Card
              key={subscription.id}
              className={subscription.stripeSubscription?.status === 'canceled' ? 'disabled' : ''}
            >
              <CardBody>
                <Row className="subscription-header">
                  <Col sm="12">
                    <h2 data-test="subscription-title" className="subscriptionTitle">
                      {subscription.plan.name}
                    </h2>
                    {/* Upgrade Subscription */}
                    {((!(
                      subscription.plan.type === PLAN_TYPE.AGENCY &&
                      subscription?.stripeSubscription?.plan?.metadata?.billingCycle === BILLING_CYCLE.ANNUAL
                    ) &&
                      subscription.stripeSubscription?.status !== 'canceled') ||
                      (subscription.plan.type === PLAN_TYPE.FREE_TRIAL &&
                        subscription.stripeSubscription?.status === 'canceled')) && (
                      <Button
                        className="btn-round float-right"
                        style={{ margin: 0 }}
                        color="purple"
                        onClick={() => {
                          history.push(GET_STARTED_STEP_1_PATH, {
                            currentSubscription: subscription,
                          });
                        }}
                      >
                        <i className="fa fa-arrow-circle-up" />
                        {t('common.upgradePlan')}
                      </Button>
                    )}
                    {/* Reactivate Expired Subscription */}
                    {subscription.plan.type !== PLAN_TYPE.FREE_TRIAL &&
                      subscription.stripeSubscription?.status === 'canceled' && (
                        <Button
                          className="btn-round float-right"
                          style={{ margin: 0 }}
                          color="success"
                          onClick={() => {
                            history.push(GET_STARTED_STEP_1_PATH, {
                              currentSubscription: subscription,
                              reactivate: true,
                            });
                          }}
                        >
                          <i className="fa fa-refresh" />
                          {t('subscriptions.reactivateSubscription')}
                        </Button>
                      )}
                    {/* Expired */}
                    {subscription.stripeSubscription?.status === 'canceled' && (
                      <h4 className="subscriptionExpiredText">{t('dashboard.subscriptionDetailsExpired')}</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" xl="8" className="website">
                    {!(
                      (subscription.plan.type === PLAN_TYPE.FREE_TRIAL ||
                        subscription.plan.type === PLAN_TYPE.BASIC ||
                        subscription.plan.type === PLAN_TYPE.PRO) &&
                      subscription.websites.length > 0
                    ) && (
                      <Row className="sk-connect-website-row">
                        <Col sm="12" className="sk-connect-website-col">
                          {subscription.websites.filter((website: any) => website.active).length <
                            subscription.plan.maxDomains && (
                            <>
                              <h3>{t('dashboard.connectAWebsite')}</h3>
                              <span>
                                <Button
                                  id={'sk-connect-website-btn-' + subscription.id}
                                  className="btn-round sk-connect-website-btn no-hide"
                                  color="success"
                                  onClick={() => {
                                    checkWebsiteActivations(subscription)
                                      ? connectNewWebsite(subscription)
                                      : setTooltipOpen('sk-connect-website-btn-' + subscription.id);
                                  }}
                                >
                                  <i className="fa fa-plus" /> {t('dashboard.connectNewWebsiteButton')}
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={'sk-connect-website-btn-' + subscription.id}
                                  trigger="hover"
                                >
                                  {t('dashboard.connectNewWebsiteButtonTooltip')}
                                </UncontrolledTooltip>
                                {(subscription.plan.type === PLAN_TYPE.AGENCY ||
                                  subscription.plan.type === PLAN_TYPE.DESIGNER) && (
                                  <>
                                    <ActivationsReachedTooltip
                                      tooltipOpen={tooltipOpen}
                                      setTooltipOpen={setTooltipOpen}
                                      subscription={subscription}
                                      target={'sk-connect-website-btn-' + subscription.id}
                                    />

                                    {subscription.websites.filter((website: any) => !website.active).length > 0 && (
                                      <>
                                        <Button
                                          id={'sk-existing-site-btn-' + subscription.id}
                                          className="btn-round sk-connect-website-btn btn-charcoal"
                                          onClick={() => {
                                            checkWebsiteActivations(subscription)
                                              ? showInactiveWebsitesModal(subscription.id)
                                              : setTooltipOpen('sk-existing-site-btn-' + subscription.id);
                                          }}
                                        >
                                          {t('dashboard.connectInactiveWebsiteButton')}
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target={'sk-existing-site-btn-' + subscription.id}
                                          trigger="hover"
                                        >
                                          {t('dashboard.connectInactiveWebsiteButtonTooltip')}
                                        </UncontrolledTooltip>
                                        <ActivationsReachedTooltip
                                          tooltipOpen={tooltipOpen}
                                          setTooltipOpen={setTooltipOpen}
                                          subscription={subscription}
                                          target={'sk-existing-site-btn-' + subscription.id}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </>
                          )}
                          {(subscription.plan.type === PLAN_TYPE.AGENCY ||
                            subscription.plan.type === PLAN_TYPE.DESIGNER) &&
                            subscription.websites.filter((website: any) => website.active).length >=
                              subscription.plan.maxDomains && (
                              <>
                                <p>
                                  <strong>{t('dashboard.maxActiveWebsitesReached')}</strong>
                                </p>
                                <Button
                                  className="btn-round sk-connect-website-btn btn-purple"
                                  onClick={() => {
                                    history.push(GET_STARTED_STEP_1_PATH + '?selectedPlanId=607e4ebfce210e00b8f31231');
                                  }}
                                >
                                  {t('dashboard.upgradeToAgency')}
                                </Button>
                              </>
                            )}
                        </Col>
                      </Row>
                    )}
                    {subscription.websites
                      .filter((website: any) => website.active)
                      .reverse()
                      .map((website: any, index: number) => {
                        return (
                          <Row key={website.id}>
                            <Col sm="12">
                              {/* Website Menu */}
                              <WebsiteMenu
                                subscription={subscription}
                                website={website}
                                showWebsiteSettingsModal={showWebsiteSettingsModal}
                                enableTransferWebsite={enableTransferWebsite}
                                showTransferWebsitesModal={showTransferWebsitesModal}
                                showConfirmRemoveWebsiteModal={showConfirmRemoveWebsiteModal}
                              />
                              {/* Website Summary */}
                              <span
                                className={
                                  website.lastSKRequestDate ? 'statusIndicator connected' : 'statusIndicator connecting'
                                }
                              ></span>
                              <span className="website-details">
                                <h3>{website.title || website.domain}</h3>
                                {website.title && (
                                  <p>
                                    <DomainLink domain={website.domain}></DomainLink>
                                  </p>
                                )}
                                {!website.lastSKRequestDate && (
                                  <>
                                    <p>{t('dashboard.connectWebsite')}</p>
                                    <Button
                                      className="btn-round no-hide"
                                      color="primary"
                                      onClick={() => showWebsiteSettingsModal(subscription, website)}
                                      disabled={subscription.stripeSubscription?.status === 'canceled'}
                                    >
                                      {t('dashboard.websiteStatusConnect')}
                                    </Button>
                                  </>
                                )}
                              </span>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                  <Col lg="6" xl="4" className="subscription">
                    <h3>{t('dashboard.subscriptionSectionHeader')}</h3>
                    <SubscriptionSummary subscription={subscription} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        })}
    </>
  );
};

export default Subscriptions;
