import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import moment from 'moment';

import { BILLING_CYCLE, PLAN_TYPE } from 'consts';
import { checkActiveWebsites, checkWebsiteActivations } from 'functions';
import { GET_STARTED_STEP_1_PATH } from 'routes';

import UnlimitedPlanCounter from 'components/UnlimitedPlanCounter';

const SubscriptionSummary: React.FunctionComponent<{
  subscription: any;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const websiteActivationsResetDate = moment
    .utc(props.subscription.expiryDate)
    .add(1, 'day')
    .set({
      year: moment()
        .utc()
        .add(moment().utc().get('month') === 11 ? 1 : 0, 'month') // Add one month if in Dec
        .get('year'),
      month: moment()
        .utc()
        .isSameOrAfter(
          moment.utc(props.subscription.expiryDate).set({ year: moment().get('year'), month: moment().get('month') }),
        )
        ? moment().utc().add(1, 'month').get('month')
        : moment().utc().get('month'),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const websiteActivationsResetStartDate = moment
    .utc(websiteActivationsResetDate)
    .subtract(1, 'month')
    .subtract(1, 'day');

  return (
    <>
      {/* Plan */}
      <p>
        <b>{t('dashboard.subscriptionDetailsPlan')}:</b> {props.subscription.plan.name}
      </p>

      {/* Active Websites */}
      {(props.subscription.plan.type === PLAN_TYPE.AGENCY || props.subscription.plan.type === PLAN_TYPE.DESIGNER) && (
        <p className={checkActiveWebsites(props.subscription) ? '' : 'text-danger'}>
          <b>{t('dashboard.subscriptionDetailsActiveWebsites')}:</b>{' '}
          {props.subscription.websites.filter((website: any) => website.active).length}/
          {props.subscription.plan.id === '5f8621e1efceda001ac6d8c4' ? (
            <UnlimitedPlanCounter />
          ) : (
            props.subscription.plan.maxDomains
          )}
        </p>
      )}

      {/* Activation Count */}
      {(props.subscription.plan.type === PLAN_TYPE.AGENCY || props.subscription.plan.type === PLAN_TYPE.DESIGNER) && (
        <p className={checkWebsiteActivations(props.subscription) ? '' : 'text-danger'}>
          <b>{t('dashboard.subscriptionDetailsWebsiteActivations')}:</b>{' '}
          {
            props.subscription.websites.filter((website: any) =>
              moment.utc(website.lastActivationDate).isSameOrAfter(websiteActivationsResetStartDate),
            ).length
          }
          /
          {props.subscription.plan.id === '5f8621e1efceda001ac6d8c4' ? (
            <UnlimitedPlanCounter />
          ) : (
            props.subscription.plan.maxDomains
          )}{' '}
          {props.subscription.plan.id !== '5f8621e1efceda001ac6d8c4' && (
            <>
              <i id={'WebsiteActivationsTooltipIcon' + props.subscription.id} className="fa fa-info-circle"></i>

              <UncontrolledTooltip
                className="large-tooltip"
                placement="right"
                autohide={false}
                hideArrow={true}
                target={'WebsiteActivationsTooltipIcon' + props.subscription.id}
              >
                <p>
                  <b>{t('dashboard.subscriptionDetailsWebsiteActivations')}</b>
                </p>
                <p>
                  <Trans i18nKey="dashboard.subscriptionDetailsWebsiteActivationsInfo">
                    Connecting a new website or restoring an inactive one will count towards your Monthly Activations.
                    This will reset on the
                    <b>
                      {{
                        websiteActivationsResetDate: websiteActivationsResetDate.local().format('MMM D, YYYY'),
                      }}
                    </b>
                    .
                  </Trans>
                </p>
                <p>
                  <b>{t('dashboard.subscriptionDetailsWebsiteActivationsInfoUpgrade')}</b>
                </p>
                {props.subscription.plan.type !== PLAN_TYPE.AGENCY && (
                  <Button
                    className="btn-round btn-purple"
                    onClick={() => {
                      history.push(GET_STARTED_STEP_1_PATH + '?selectedPlanId=607e4ebfce210e00b8f31231');
                    }}
                  >
                    {t('dashboard.upgradeToAgency')}
                  </Button>
                )}
                {props.subscription.plan.type === PLAN_TYPE.AGENCY && (
                  <Button
                    className="btn-round btn-success"
                    onClick={() => {
                      history.push('/help');
                    }}
                  >
                    {t('newSubscription.step1ContactUs')}
                  </Button>
                )}
              </UncontrolledTooltip>
            </>
          )}
        </p>
      )}

      {/* Billing Cycle */}
      {props.subscription.stripeSubscription &&
        props.subscription.stripeSubscription?.status !== 'canceled' &&
        props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && (
          <p>
            <b>{t('dashboard.subscriptionDetailsBillingCycle')}:</b>{' '}
            {props.subscription.autoRenew ? (
              <>
                {props.subscription.stripeSubscription?.plan.metadata.billingCycle === BILLING_CYCLE.ANNUAL
                  ? t('dashboard.subscriptionDetailsBillingCycleAnnual')
                  : t('dashboard.subscriptionDetailsBillingCycleMonthly')}
              </>
            ) : (
              <span className="text-danger">
                {props.subscription.stripeSubscription?.status === 'canceled'
                  ? t('dashboard.subscriptionDetailsExpired')
                  : t('dashboard.subscriptionDetailsAutoRenewDisabled')}
              </span>
            )}
          </p>
        )}

      {/* Free Trial/Unlimited Expiry */}
      {((props.subscription.plan.type === PLAN_TYPE.FREE_TRIAL && props.subscription.expiryDate) ||
        !props.subscription.stripeSubscription) && (
        <p>
          <b>
            {props.subscription.stripeSubscription?.status === 'canceled'
              ? t('dashboard.subscriptionDetailsExpired')
              : t('dashboard.subscriptionDetailsExpires')}
            :
          </b>{' '}
          {props.subscription.stripeSubscription?.status === 'canceled' ? (
            <span className="text-danger">
              {moment.utc(props.subscription.expiryDate).local().format('MMM D, YYYY')}
            </span>
          ) : props.subscription.expiryDate ? (
            moment.utc(props.subscription.expiryDate).add(1, 'day').local().format('MMM D, YYYY')
          ) : (
            t('dashboard.subscriptionDetailsNoExpiry')
          )}
        </p>
      )}

      {/* Auto Renew */}
      {props.subscription.plan.type !== PLAN_TYPE.FREE_TRIAL && props.subscription.stripeSubscription && (
        <p>
          <b>
            {props.subscription.autoRenew
              ? t('dashboard.subscriptionDetailsAutoRenew')
              : props.subscription.stripeSubscription?.status === 'canceled'
              ? t('dashboard.subscriptionDetailsExpired')
              : t('dashboard.subscriptionDetailsExpires')}
            :
          </b>{' '}
          {props.subscription.stripeSubscription?.status === 'canceled' ? (
            <span className="text-danger">
              {' '}
              {moment.utc(props.subscription.expiryDate).local().format('MMM D, YYYY')}
            </span>
          ) : (
            moment.utc(props.subscription.expiryDate).add(1, 'day').local().format('MMM D, YYYY')
          )}
        </p>
      )}
    </>
  );
};

export default SubscriptionSummary;
