import { gql } from '@apollo/client';

import { ME_FIELDS } from '../fragments/user';

export const ME = gql`
  ${ME_FIELDS}
  query Me {
    me {
      ...MeFields
    }
  }
`;
